import React, { useState, useEffect, useContext } from "react";
import MapComponent from '../Components/MapComponent';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import Slider from "react-slick";
import slick from 'slick-carousel/slick/slick.css';

const NavSpacer = styled.div`
  height: 80px;
`;

// Styled components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
  margin-right: 20vw;
  margin-left: 20vw;
  gap: 1.2vw;
  overflow: hidden;
  @media (max-width: 1100px) {
    margin-right: 10vw;
    margin-left: 10vw;
  }
  @media (max-width: 768px) {
    margin-right: 0vw;
    margin-left: 0vw;
  }
`;

const Title = styled.h1`
  color: #333;
  font-size: 1.5vw;
  margin-bottom: 1vw;
  margin-left: 1vw;
  @media (max-width: 764px) {
    font-size: 2.5vw;
  }
`;

const GalleryContainer = styled.div`
  display: flex;
  gap: .5vw; // Space between main image and thumbnails
  margin-bottom: 1.2vw;
`;

const MainImage = styled.div`
  flex: 1;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  padding-top: 50%; // This will give you a square aspect ratio for the main image
  border-radius: 15px;
`;

const ThumbnailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Creates a 2x2 grid
  grid-gap: .5vw;
  flex: 1;
`;

const Thumbnail = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  padding-top: calc(50% - 5px); // This will give a square aspect ratio considering the gap
`;


const DescriptionBox = styled.div`
  padding: 1.2vw;
  border: 1px solid #ddd;
  border-radius: 4px;
  @media (max-width: 768px) {
    margin-right: 3vw;
    margin-left: 3vw;
    border: none;
  }
`;

const HalfWidthBoxContainer = styled.div`
  display: flex;
  justify-content: space-between; // This will put a little space between the two boxes
  margin-bottom: 50px;
  gap: 20px; // Adds space between the two boxes
  @media (max-width: 764px) {
    display: block;
    
  }
`;

const HalfWidthBox = styled.div`
  width: calc(50% - 10px); // Adjusts for the gap
  height: 25vw;
  padding: 1vw;
  border: 1px solid #ddd;
  border-radius: 4px;
  @media (max-width: 764px) {
    height: 100vw;
    width: 97.5%;
    margin-bottom: 1vw;
  }
`;

const HalfWidthBoxDesc = styled.div`
  width: calc(50% - 10px); // Adjusts for the gap
  height: 25vw;
  padding: 1vw;
  border: 1px solid #ddd;
  border-radius: 4px;
  @media (max-width: 764px) {
    width: 97.5%;
    height: auto;
    margin-bottom: 3vw;
    border: none;
    margin-right: 3vw;
    margin-left: 3vw;
  }
`;

const AddressText = styled.p`
  font-size: 1.15vw;
  color: #666;
  margin-bottom: 0;
  @media (max-width: 764px) {
    font-size: 5vw;
    margin-bottom: 1vw;
    &:after {
    content: '';
    display: block;
    width: 95%;
    height: 1px;
    background-color: #ddd;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }
  }
`;

const DescTitle = styled.p`
  font-size: 1.4vw;
  color: #666;
  margin-bottom: 1vw;
  @media (max-width: 764px) {
    font-size: 5vw;
    &:after {
    content: '';
    display: block;
    width: 90%;
    height: 1px;
    background-color: #ddd;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }
  }
`;

const Desc = styled.p`
  font-size: 1vw;
  color: #666;
  margin-bottom: 0;
  @media (max-width: 764px) {
    font-size: 4vw;
  }
`;

const Descriptor = styled.p`
  font-size: 1vw;
  @media (max-width: 764px) {
    font-size: 4vw;
    color: #666;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  align-items: center; // Center align vertically
  justify-content: center; // Center align horizontally
  height: 80vh;
  width: 100%;
  margin: auto;
  background-color: black;
  text-align: center;
  overflow: hidden; // Hide the overflowing image
`;

const ImageWrapper = styled.div`
  height: 80vh; // Set a fixed height for the wrapper
  width: 100%; // Ensure the width spans the full container
  margin: auto;
  overflow: hidden;
`;

const Slide = styled.div`
  height: 80vh;
  width: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  max-height: 80vh; // Ensure the image height does not exceed the container
  max-width: 100%; // Ensure the image width does not exceed the container
  object-fit: contain; // Maintain aspect ratio without cropping
  margin: auto;
  overflow: hidden;
`;




const PropertyPage = () => {
  const { propertyId } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [property, setProperty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://d32zbq7kdn6rwo.cloudfront.net/latest_response.json');
        const data = await response.json();
        const foundProperty = data.ads.find(ad => ad.ad_id.toString() === propertyId);

        if (foundProperty) {
          setProperty(foundProperty);
        } else {
          setError('Property not found');
        }
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [propertyId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (!property) {
    return <div>Loading property details...</div>;
  }

  const settings = {
    dots: false, // Shows dot indicators at the bottom
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    arrows: false, // Hide arrows
    autoplay: true, // Enable autoplay
  };

  const mainImage = property.images[0];
  const thumbnails = property.images.slice(1, 5); // Only take the first four images for the grid

  

  return (
    <PageContainer>
      <NavSpacer />
      {isMobile ? (
        <div>
        <LeftContainer>
            <ImageWrapper>
            <Slider {...settings}>
        {property.images.map((image, index) => (
          <Slide key={index}>
      <Image src={image} alt={`Property Image ${index + 1}`} />
    </Slide>
        ))}
      </Slider>
            </ImageWrapper>
          </LeftContainer>
      </div>
      ) : (
        <div>
        <Title>{property.address}</Title>
      <GalleryContainer>
        <MainImage src={mainImage} alt="Main View" />
        <ThumbnailsContainer>
          {thumbnails.map((img, index) => (
            <Thumbnail key={index} src={img} alt={`Thumbnail ${index}`} />
          ))}
        </ThumbnailsContainer>
      </GalleryContainer>
      </div>
      )}
      <DescriptionBox>
        <AddressText>{property.full_address}</AddressText>
        <Descriptor>{property.property_type} · {property.bedrooms} bedrooms · {property.bathrooms} bathrooms</Descriptor>
      </DescriptionBox>
      <HalfWidthBoxContainer>
        <HalfWidthBoxDesc>
          
          <DescTitle>Description</DescTitle>
          <Desc>{property.description}</Desc>
        </HalfWidthBoxDesc>
        <HalfWidthBox>

          <MapComponent lat={property.latitude} lng={property.longitude} address={property.address}/>
        </HalfWidthBox>
      </HalfWidthBoxContainer>
    </PageContainer>
  );
};

export default PropertyPage;