import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import PropertyCard from '../Components/PropertyCard';
import PropertyHeader from '../Images/PropertyHeader.jpg';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // 3 columns
 
  padding: 20px; // padding around the grid
  max-width: 2200px; // maximum width of the container
  margin: 0 auto; // center the container
  
  margin-bottom: 50px;
  grid-row-gap: 10px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr); // 2 columns for medium screens
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr; // 1 column for small screens
    padding: 0;
    padding-top: 0px;
    
  }
`;

const Header = styled.div`
  position: relative;
  background-image: url(${PropertyHeader});
  background-size: cover;
  background-position: center;
  height: 76vh;
  margin-bottom: 30px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); // black tint with 50% opacity
    z-index: 1; // ensures the tint is below the text
  }
`;

const HeaderText = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;
  color: white;
  font-size: 3em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none; // hide this on mobile
  }
`;

const HeaderTextMobile = styled.div`
  display: none; // hide this on larger screens
  position: absolute;
  bottom: 80px;
  left: 30px;
  color: white;
  font-size: 2em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: block; // show this on mobile
    bottom: 180px;
  left: 20px;
  font-size: 2.2em;
  line-height: 120%;
  }
`;

const SubHeaderText = styled.div`
  position: absolute;
  bottom: 40px;
  left: 100px;
  color: white;
  font-size: 1.05em;
  z-index: 2;
letter-spacing: 2px;
line-height: 180%;
  @media (max-width: 768px) {
    font-size: 1em; // smaller font size for mobile
    left: 20px; // adjust position for mobile
    bottom: 60px;
    
  }
`;

const PropertyCardContainer = styled.div`
  // Your existing styles for the PropertyCard container
  padding-bottom: 30px; // Adjust this value to increase the space above the dots

  @media (max-width: 850px) {
    padding-bottom: 10px; 
  }
`;



const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // return original string if it's empty
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  

  useEffect(() => {
    const s3Url = 'https://d32zbq7kdn6rwo.cloudfront.net/latest_response.json'; // Replace with your actual S3 URL
    fetch(s3Url)
      .then(response => response.json())
      .then(data => setProperties(data.ads)) // Assuming the response has an `ads` field
      .then(() => setIsLoading(false))
      .catch(error => console.error('Error fetching data:', error));
}, []);// Empty dependency array means this effect will only run once after the initial render

  const handlePropertySelect = (property) => {
    navigate('/singlePage', { state: { property } });
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Header>
        <HeaderText>EXPLORE SWIPES <br /> EXCLUSIVE LISTINGS</HeaderText>
        <HeaderTextMobile>
          EXPLORE<br />
          SWIPES<br />
          EXCLUSIVE<br />
          LISTINGS
        </HeaderTextMobile>
        <SubHeaderText>Representing a Bespoke Collection of Dublin's Finest Properties</SubHeaderText>
      </Header>
      <Container>
        {properties.map((property, index) => (
          <PropertyCardContainer key={index} onClick={() => handlePropertySelect(property)}>
              <PropertyCard
                price={property.rent}
                address={property.address}
                imageSrc={property.large_thumbnail_url}
                type={capitalizeFirstLetter(property.property_type)}
                bedroom={property.bedrooms}
                bathroom={property.bathrooms}
              />
            </PropertyCardContainer>
        ))}
      </Container>
    </>
  );
}

export default Properties;