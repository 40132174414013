import React from 'react';
import styled from 'styled-components';
import Office from '../Images/Office.jpg';
import handHolding from '../Images/hand-holding-house-real-estate-property-model-jpg.webp'
import Person2 from '../Images/worker.jpg';

const Container = styled.div`
  margin-bottom: 100px;
  margin-left: 5vw;
  margin-right: 5vw;
`;

const TeamContainer = styled.div`
  text-align: center;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const TeamTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 70px;
  margin-top: 150px;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 7vw; // Slightly smaller font size on mobile
    margin-top: 100px;
    margin-bottom: 50px;
  }
`;

const MemberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;


const MemberImage = styled.img`
  width: 400px; // Adjust as needed
  height: 550px; // Adjust as needed
  border-radius: 10px; // Slightly rounded corners for a softer look
  margin-right: 20px;
  border: 4px solid #f0f0f0; // Solid border, change color as needed
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Adds a subtle shadow
  transition: transform 0.3s ease; // Smooth transition for hover effect
object-fit: cover;
  @media (max-width: 768px) {
    width: 80%; // Adjust width to be responsive
    height: auto; // Adjust height to maintain aspect ratio
    margin-bottom: 10px;
    margin-right: 0px;
  }

  &:hover {
    transform: scale(1.05); // Slightly enlarges the image on hover
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // Slightly larger shadow on hover for a dynamic effect
  }
`;

const MemberDescription = styled.div`
  text-align: left;
  max-width: 500px;
  margin-left: 60px;
  margin-right: 60px;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center; // Center align the text on smaller screens
  }
`;

const MemberName = styled.h3`
  font-size: 1.5em; // Example font size, adjust as needed
  color: #333; // Example color, adjust as needed
  margin-bottom: 1.5em; // Example margin, adjust as needed
text-align: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 1em;
  }
`;

// Styled component for the member's bio
const MemberBio = styled.p`
  font-size: 1.2em; // Example font size, adjust as needed
  color: #666; // Example color, adjust as needed
  line-height: 1.8; // Example line height, adjust as needed
  @media (max-width: 768px) {
   text-align: left;
   margin-left: 5px;
  }
`;

const FlippedMemberContainer = styled(MemberContainer)`
  flex-direction: row-reverse; // Flip the order of flex items
  margin-top: 150px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 80px; // Changed to column for mobile to match the MemberContainer
  }
`;


const BriefAbout = () => {
    return (
    <Container>
      <TeamContainer>
      <TeamTitle>A LITTLE ABOUT US</TeamTitle>
      <MemberContainer>
        <MemberImage src={handHolding} alt="Team Member Name" />
        <MemberDescription>
          <MemberName>Property Made Easy</MemberName>
          <MemberBio>At Swipe Properties, we're committed to securing the highest possible price for your property. Our team's profound local knowledge, honed over 20 years in the Dublin property market, enables us to provide realistic valuations that truly reflect your property's worth. We leverage online pre-viewings to tap into a global market, ensuring your property is showcased to a broad audience.</MemberBio>
        </MemberDescription>
      </MemberContainer>
    </TeamContainer>
    </Container>
  )
}

export default BriefAbout;