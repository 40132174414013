import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import BackImg from '../Images/vaulation.jpg';
import Email from '../Images/email.svg'; 
import Phone from '../Images/phone.svg'; 
import Address from '../Images/address.svg'; 
import Pig from '../Images/pig.svg'; 
import Customer from '../Images/customer.svg'; 
import Graph from '../Images/graph.svg'; 



const Background = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-image: url(${BackImg});
  background-size: cover;
  filter: blur(2px);
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;


const BackgroundContainer = styled.div`
  position: relative; 
  width: 100%;
  min-height: 130vh; 
  overflow: hidden;
  @media (max-width: 800px) {
    min-height: 100vh; 
  }
   
    
`;

const Content = styled.div`
  position: relative;
  
  
`;



const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  height: 100vh;
  position: relative; 
  margin-top: 50px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: auto;

    &::after {
      display: none; 
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 49%;
    
    width: 1px; 
    background-color: #c0c0c0; 
    z-index: 1;
  }
`;





const Box = styled.div`
  width: 533px;
  height: 450px;
  padding: 20px;
  padding-bottom: 200px;

  @media (max-width: 800px) {
    width: 90%;
    height: auto;
    padding-bottom: 20px;
   
    
  }

  @media (max-width: 1024px) {
   
  }
`;


const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 800px) {
    gap: 10px;
  }

  @media (max-width: 1024px) {
   
  }
`;

const Icon = styled.img`
  width: 55px;
  height: 55px;
  color: white;

  @media (max-width: 800px) {
    width: 45px;
    height: 45px;
  }

  @media (max-width: 1024px) {
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid  white;
  background-color: transparent;
  margin-bottom: 15px;
  
  
  color: white; 
  font-size: 20px; 
  &:focus {
    outline: none;
    
  }
`;

const TextArea = styled.textarea`
   border: 1px solid  white;
  background-color: transparent;
  height: 150px;
  color: white; 
  font-size: 20px; 
  padding: 5px;
  &:focus {
    outline: none;
   
  }
`;



const LeftBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  margin-bottom: 40px;
  
`;



const TitleRight = styled.div`
color: white;
letter-spacing: 6px;

font-size: 35px;
margin-bottom: 3px;
line-height: 120%;

@media (max-width: 800px) {
   
    font-size: 28px;
  }

`;

const TitleLeft = styled.div`
color: white;
letter-spacing: 2px;

font-size: 35px;
margin-bottom: 50px;


@media (max-width: 800px) {
    margin-top: 50px;
    font-size: 28px;
  }


`;

const Subtitle = styled.div`
color: white;
letter-spacing: 2px;
font-family: 'Syncopate', sans-serif;
font-size: 20px;
margin-bottom: 15px;

@media (max-width: 800px) {
    
    font-size: 17.5px;
  }


`;

const Label = styled.label`
  color: #c0c0c0;
  margin-bottom: 5px; 
  
`;

const Text = styled.div`
  align-self: flex-start;
  position: relative; 
  padding-top: 10px;
 font-size: 15.5px;
 color: #c0c0c0;
 letter-spacing: 1px;
line-height: 140%;
  
`;

const Titletext = styled.div`
align-self: flex-start;

font-size: 17.5px;

`;


const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7px;
`;


const Button = styled.button`
  width: 100%; 
  padding: 20px;
  border: 1px solid white; 
  background-color: transparent; 
  color: white;
  
  font-size: 18px; 
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;

  &:hover {
    color: #000;
    background-color: #c0c0c0; 
    
  }
`;

const SubtitleRight = styled.div`
color: white;
letter-spacing: 2px;

font-size: 20px;
margin-bottom: 25px;

@media (max-width: 800px) {
    
    font-size: 17.5px;
  }


`;

const SubmittedFormTitle = styled.div`
  color: #3dcd5f;


letter-spacing: 6px;

font-size: 35px;
margin-bottom: 60px;


@media (max-width: 800px) {
   
    font-size: 28px;
  }

`;


const SubmittedFormText = styled.p`
  color: white;
  font-size: 20px;

  margin-bottom: 30px;
`;

const SubmittedFormStrong = styled.strong`
  color: #c0c0c0;
  font-size: 20px;
`;

const SubmittedFormContainer = styled.div`
  /* Add your styles for the div here */
 
`;

const Valuation = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    property_location: '',
    user_message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs.sendForm('service_k66zxis', 'template_07wewl1', form.current, 'wXoasOPVtxw7WJgHe')
      .then((result) => {
          console.log(result.text);
          setIsSubmitted(true);
          setIsLoading(false);
      }, (error) => {
          console.log(error.text);
          setIsLoading(false); 
      });
  };

    
    return (
      <>
          <BackgroundContainer>
        <Background />
      
        <Content>
          <Container>
          <Box>
            <LeftBoxContent>
              <TitleLeft>Get The Best Price For Your Property</TitleLeft>
              
              <InfoItem>
                <Icon src={Graph} alt="Phone" />
                <TextWrapper>
    <Titletext>Local Experts</Titletext>
    <Text>Earnest (formerly Dublin Letting & Property Management) has been founded in 2004.</Text>
  </TextWrapper>
              </InfoItem>
              <InfoItem>
                <Icon src={Customer} alt="Phone" />
                <TextWrapper>
    <Titletext>Customer Service</Titletext>
    <Text>Get a response to your query within 1 hour during weekdays (9.30am to 5.30pm).</Text>
  </TextWrapper>
              </InfoItem>
              <InfoItem>
                <Icon src={Pig} alt="Phone" />
                <TextWrapper>
    <Titletext>Peace of mind</Titletext>
    <Text>Our services are free of charge until your property is let or sold.</Text>
  </TextWrapper>
              </InfoItem>
            </LeftBoxContent>
          </Box>
          <Box>
  {isSubmitted ? (
    <SubmittedFormContainer>
      <SubmittedFormTitle>Your Message Has Been Sent!</SubmittedFormTitle>
      <SubmittedFormText><SubmittedFormStrong>Name:</SubmittedFormStrong> {formData.user_name}</SubmittedFormText>
      <SubmittedFormText><SubmittedFormStrong>Email:</SubmittedFormStrong> {formData.user_email}</SubmittedFormText>
      <SubmittedFormText><SubmittedFormStrong>Phone:</SubmittedFormStrong> {formData.user_phone}</SubmittedFormText>
      <SubmittedFormText><SubmittedFormStrong>Property Location:</SubmittedFormStrong> {formData.property_location}</SubmittedFormText>
      <SubmittedFormText><SubmittedFormStrong>Message:</SubmittedFormStrong> {formData.user_message}</SubmittedFormText>
    </SubmittedFormContainer>
  ) : (
    <Form ref={form} onSubmit={sendEmail}>
      <TitleRight>Request A Free Valuation</TitleRight>
      <SubtitleRight>Schedule a Call with us</SubtitleRight>

      <Label htmlFor="name">Name</Label>
      <Input type="text" name="user_name" value={formData.user_name} onChange={handleChange} />

      <Label htmlFor="email">Email</Label>
      <Input type="email" name="user_email" value={formData.user_email} onChange={handleChange} />

      <Label htmlFor="location">Property location</Label>
      <Input type="text" name="property_location" value={formData.property_location} onChange={handleChange} />

      <Label htmlFor="phone">Phone</Label>
      <Input type="tel" name="user_phone" value={formData.user_phone} onChange={handleChange} />

      <Label htmlFor="message">Message</Label>
      <TextArea name="user_message" value={formData.user_message} onChange={handleChange}></TextArea>

      <Button type="submit" disabled={isLoading}>Submit</Button> 
    </Form>
  )}
</Box>
          </Container>
        </Content>
        </BackgroundContainer>
      </>
    )
  }
  
  export default Valuation