import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WordpressAPI from '../API/WordpressAPI';

import styled from 'styled-components';
import Office from '../Images/Office.jpg';
const Container = styled.div`


`;


const Header = styled.div`
  height: 76vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: center;
    filter: blur(0px);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }
`;

const HeaderText = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;
  color: white;
  font-size: 3em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderTextMobile = styled.div`
  display: none;
  position: absolute;
  bottom: 80px;
  left: 30px;
  color: white;
  font-size: 2em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: block;
    bottom: 180px;
    left: 20px;
    font-size: 2.2em;
    line-height: 120%;
  }
`;

const SubHeaderText = styled.div`
  position: absolute;
  bottom: 40px;
  left: 100px;
  color: white;
  font-size: 1.05em;
  z-index: 2;
  letter-spacing: 2px;
  line-height: 180%;
  @media (max-width: 768px) {
    font-size: 1em;
    left: 20px;
    bottom: 60px;
    padding-right: 1px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 80px;
  
  

  @media (max-width: 1024px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 15px;
    padding-top: 50px;
  }
`;

const Title = styled.div`
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Syncopate', sans-serif;
  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const BlogContent = styled.div`
  font-size: 1.1em;
  line-height: 1.6;
  color: #444;
  margin-top: 70px;
  
  

  p {
    margin-bottom: 80px;
  }

  h2, h3, h4, h5, h6 {
    margin-top: 24px;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
    font-family: 'Syncopate', sans-serif;
    font-weight: 500;
    
  }

  a {
    color: #007bff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  // Add styles for other elements like lists, blockquotes etc.
`;


const BlogsPage = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchPost = async () => {
        try {
          const response = await WordpressAPI.get(`/posts/${postId}?_embed`);
          setPost(response.data);
          setIsLoading(false);
        } catch (err) {
          setError(err.message);
          setIsLoading(false);
        }
      };
  
      fetchPost();
    }, [postId]);
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
    if (!post) {
      return <div>No post found.</div>;
    }
  
    const imageUrl = post?._embedded?.['wp:featuredmedia']?.[0]?.source_url;  
  
    return (
        <Container>
          <Header backgroundImage={imageUrl || ''}>
            <HeaderText dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
            <HeaderTextMobile dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
            <SubHeaderText dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
          </Header>
          <ContentContainer>
            <Title dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
            <BlogContent dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </ContentContainer>
        </Container>
      );
      
  };
  
  export default BlogsPage;
  
