import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';
import BackImg from '../Images/dublin.jpg';
import Email from '../Images/email.svg'; 
import Phone from '../Images/phone.svg'; 
import Address from '../Images/address.svg'; 

const Background = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background-image: url(${BackImg});
  background-size: cover;
  filter: blur(2px);
  z-index: -1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;


const BackgroundContainer = styled.div`
  position: relative; 
  width: 100%;
  min-height: 110vh; 
  overflow: hidden;
   
`;

const Content = styled.div`
  position: relative;
  
  
`;



const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  height: 100vh;
  position: relative; 
margin-top: 50px;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: auto;

    &::after {
      display: none; 
    }
  }

 
`;





const Box = styled.div`
  width: 450px;
  height: 450px;
  padding: 20px;
  padding-bottom: 200px;

  @media (max-width: 800px) {
    width: 90%;
    height: auto;
    padding-bottom: 20px;
   
    
  }

  @media (max-width: 1024px) {
   
  }

  @media (min-width: 2000px) {
    width: 550px;
  
  }
`;


const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 800px) {
    gap: 10px;
  }

  @media (max-width: 1024px) {
   
  }
`;

const Icon = styled.img`
  width: 35px;
  height: 35px;
  color: white;

  @media (max-width: 800px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 1024px) {
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid  #c0c0c0;
  background-color: transparent;
  margin-bottom: 15px;
  
  
  color: white; 
  font-size: 20px; 
  &:focus {
    outline: none;
    
  }
`;

const TextArea = styled.textarea`
   border: 1px solid  #c0c0c0;
  background-color: transparent;
  height: 150px;
  color: white; 
  font-size: 20px; 
  padding: 5px;
  &:focus {
    outline: none;
   
  }
`;



const LeftBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  margin-bottom: 20px;
  
`;



const TitleRight = styled.div`
color: white;
letter-spacing: 6px;
font-family: 'Syncopate', sans-serif;
font-size: 35px;
margin-bottom: 15px;



@media (max-width: 800px) {
   
    font-size: 28px;
  }

`;

const TitleLeft = styled.div`
color: white;
letter-spacing: 2px;
font-family: 'Syncopate', sans-serif;
font-size: 35px;


@media (max-width: 800px) {
    margin-top: 50px;
    font-size: 28px;
  }


`;

const Subtitle = styled.div`
color: white;
letter-spacing: 2px;
font-family: 'Syncopate', sans-serif;
font-size: 20px;
margin-bottom: 35px;

@media (max-width: 800px) {
    
    font-size: 17.5px;
  }


`;

const Label = styled.label`
  color: #c0c0c0;
  margin-bottom: 5px; 
  font-family: 'Syncopate', sans-serif;
`;

const Text = styled.div`
  align-self: flex-start;
  position: relative; 
  padding-top: 10px;
 font-size: 13px;
 font-family: 'Syncopate', sans-serif;
 letter-spacing: 1px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; 
    width: 100%;
    height: 1px; 
    background-color: #c0c0c0; 
  }
`;

const Titletext = styled.div`
align-self: flex-start;
font-family: 'Syncopate', sans-serif;
font-size: 13.5px;

`;


const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 7px;
`;


const Button = styled.button`
  width: 100%; 
  padding: 20px;
  border: 1px solid #c0c0c0; 
  background-color: transparent; 
  color: white;
  font-family: 'Syncopate', sans-serif;
  font-size: 18px; 
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;

  &:hover {
    color: #000;
    background-color: #c0c0c0; 
    
  }
`;


const SubmittedFormContainer = styled.div`
  /* Add your styles for the div here */
 
`;

const SubmittedFormTitle = styled.div`
  color: #3dcd5f;


letter-spacing: 6px;
font-family: 'Syncopate', sans-serif;
font-size: 35px;
margin-bottom: 60px;


@media (max-width: 800px) {
   
    font-size: 28px;
  }

`;

const SubmittedFormText = styled.p`
  /* Add your styles for the p here */
  color: white;
  font-size: 20px;
  font-family: 'Syncopate', sans-serif;
  margin-bottom: 30px;
  
`;

const SubmittedFormStrong = styled.strong`
  /* Add your styles for the strong here */
  color: #c0c0c0;
  
  font-size: 20px;
  
`;

const Contact = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = new FormData(form.current);
    setFormData({
      name: data.get('user_name'),
      email: data.get('user_email'),
      phone: data.get('user_phone'),
      message: data.get('user_message')
    });

    emailjs.sendForm('service_k66zxis', 'template_6qyhkpb', form.current, 'wXoasOPVtxw7WJgHe')
      .then((result) => {
          console.log(result.text);
          setIsSubmitted(true);
          setIsLoading(false); // set isSubmitted to true here
      }, (error) => {
          console.log(error.text);
          setIsLoading(false); 
      });
  };


    return (
      <>
          <BackgroundContainer>
        <Background />
      
        <Content>
          <Container>
          <Box>
            <LeftBoxContent>
              <TitleLeft>CONTACT DETAILS</TitleLeft>
              <Subtitle>SWIPE PROPERTY TEAM</Subtitle>
              <InfoItem>
                <Icon src={Phone} alt="Phone" />
                <TextWrapper>
    <Titletext>PHONE</Titletext>
    <Text>+353-1-234-5678</Text>
  </TextWrapper>
              </InfoItem>
              <InfoItem>
                <Icon src={Email} alt="Phone" />
                <TextWrapper>
    <Titletext>EMAIL</Titletext>
    <Text>ADAMNAPPER007@GMAIL.COM</Text>
  </TextWrapper>
              </InfoItem>
              <InfoItem>
                <Icon src={Address} alt="Phone" />
                <TextWrapper>
    <Titletext>ADDRESS</Titletext>
    <Text>71 CHAPEL FARM ROAD LUSK, K45 P030</Text>
  </TextWrapper>
              </InfoItem>
            </LeftBoxContent>
          </Box>
          <Box>
            {isSubmitted ? (
              <SubmittedFormContainer>
  <SubmittedFormTitle>YOUR MESSAGE HAS BEEN SENT!</SubmittedFormTitle>
  <SubmittedFormText><SubmittedFormStrong>Name:</SubmittedFormStrong> {formData.name}</SubmittedFormText>
  <SubmittedFormText><SubmittedFormStrong>Email:</SubmittedFormStrong> {formData.email}</SubmittedFormText>
  <SubmittedFormText><SubmittedFormStrong>Phone:</SubmittedFormStrong> {formData.phone}</SubmittedFormText>
  <SubmittedFormText><SubmittedFormStrong>Message:</SubmittedFormStrong> {formData.message}</SubmittedFormText>
</SubmittedFormContainer>
            ) : (
              <Form ref={form} onSubmit={sendEmail}>
                <TitleRight>SUBMIT A MESSAGE</TitleRight>

                <Label htmlFor="name">Name</Label>
                <Input type="text" id="name" name="user_name" />

                <Label htmlFor="email">Email</Label>
                <Input type="email" id="email" name="user_email" />

                <Label htmlFor="phone">Phone</Label>
                <Input type="tel" id="phone" name="user_phone" />

                <Label htmlFor="message">Message</Label>
                <TextArea id="message" name="user_message"></TextArea>

                <Button type="submit" disabled={isLoading}>Submit</Button> 
              </Form>
            )}
          </Box>
          </Container>
        </Content>
        </BackgroundContainer>
      </>
    )
  }
  
  export default Contact