import { useState, useEffect } from 'react';
import styled from 'styled-components';

const CardPrice = styled.p`
  font-size: 0.8rem;
  letter-spacing: 1.0px;
  margin: 0;
  position: absolute;
  bottom: 5px;
  right: 10px;
  padding: 1px;
`;

const CardTitle = styled.p`
  font-size: 1rem;
  margin: 0;
  letter-spacing: 1.0px;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 1px;
  @media (max-width: 1500px) {
    font-size: 0.8rem;
    }
    @media (max-width: 764px) {
    font-size: 3.5vw;
    }
`;

const StyledCard = styled.div`
  width: 350px !important;
  height: 350px !important;
  margin: 0;
  padding: 0;
  border-width: 2px; 
  border-color: white;
  font-size: 1rem;
  text-decoration: none;
  color: white;
  overflow: hidden;
  position: relative;
  @media (max-width: 1500px) {
    width: 300px !important;
    width: 300px !important;
    }
  @media (max-width: 1400px) {
    width: 450px !important;
    width: 450px !important;
    }
    @media (max-width: 1100px) {
    width: 350px !important;
    width: 350px !important;
    }
    @media (max-width: 800px) {
    width: 100% !important;
    width: 100% !important;
    margin-left: 5%;
    }
    @media (max-width: 768px) {
      width: 80% !important;
      width: 80% !important;
      margin-left: 10%;
    }
`;

const CardImage = styled.img`
  position: absolute;
  top: 5px;
  right: 0px;
  width: 50px; // Adjust size as needed
  height: 50px; // Adjust size as needed
`;

const CardDescription = styled.p`
  position: absolute;
  top: 60px; // Adjust based on the size of the title and image
  left: 10px;
  right: 10px;
  font-size: 1rem;
  letter-spacing: 1.0px;
  text-align: left;
  margin: 0;
  overflow: hidden; // Adjust as needed
  @media (max-width: 1500px) {
    font-size: 0.8rem;
    }
    @media (max-width: 1400px) {
    font-size: 1rem;
    }
    @media (max-width: 800px) {
    font-size: 0.9rem;
    }
`;

const CardSVG = styled.svg`
  position: absolute;
  top: 5px;
  right: 10px;
  width: 50px; 
  height: 50px;
`;

const Card = ({ src1, description, title,  }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // set initial value based on window width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  return (
    <StyledCard 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CardTitle>{title}</CardTitle>
      <CardImage src={src1} alt="Card Image" />
      <CardDescription>{description}</CardDescription>
    </StyledCard>
  );
};

export default Card;