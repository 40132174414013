import React from 'react';
import styled from 'styled-components';
import Office from '../Images/Office.jpg';

import Person2 from '../Images/worker.jpg';

const Container = styled.div`
  margin-bottom: 50px;
`;

const Header = styled.div`
  height: 76vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${Office});
    background-size: cover;
    background-position: center;
    filter: blur(0px); // Apply a slight blur to the background image
    z-index: -1; // Set to -1 so it's behind the text
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); // Black tint with 50% opacity
    z-index: -1; // Set to -1 so it's behind the text
  }
`;

const HeaderText = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;
  color: white;
  font-size: 3em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none; // hide this on mobile
  }
`;

const HeaderTextMobile = styled.div`
  display: none; // hide this on larger screens
  position: absolute;
  bottom: 80px;
  left: 30px;
  color: white;
  font-size: 2em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: block; // show this on mobile
    bottom: 180px;
  left: 20px;
  font-size: 2.2em;
  line-height: 120%;
  }
`;

const SubHeaderText = styled.div`
  position: absolute;
  bottom: 40px;
  left: 100px;
  color: white;
  font-size: 1.05em;
  z-index: 2;
letter-spacing: 2px;
line-height: 180%;
  @media (max-width: 768px) {
    font-size: 1em; // smaller font size for mobile
    left: 20px; // adjust position for mobile
    bottom: 60px;
    padding-right: 1px;
    
  }
`;

const MissionTitle = styled.h2`
  text-align: center;
  margin-top: 80px; // Adjust as needed
  font-size: 2.5em; // Adjust font size as needed
 
  font-weight: 500;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
    
  }
`;

const MissionText = styled.p`
  text-align: center;
  margin: 30px auto; // Vertical margin for spacing, auto for horizontal centering
  max-width: 900px; // Adjust width as needed for readability
  line-height: 1.6; // Adjust line height for better readability
  font-size: 20px;

  @media (max-width: 768px) {
    padding: 20px;
    
  }
 
`;

const SectionConatiner =styled.div`

margin-top: 70px;
margin-left: 50px;
margin-right: 50px;

@media (max-width: 768px) {
    margin-left: 25px;
margin-right: 25px;
  }
`;



const SectionDivider = styled.hr`
  border: none;
  height: 1px;
  background-color: grey;
  margin: 20px auto; // Centers the divider and adds vertical space
  max-width: 1500px; // Adjust this value as needed
  
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  max-width: 1500px; // Set the max width to match the SectionDivider
  margin: auto; // Center the section

  @media (max-width: 768px) {
    flex-direction: column; // Stack the items vertically on mobile
    align-items: flex-start; // Align items to the start of the container
  }
`;

const SectionTitle = styled.h3`
  margin-left: 50px; // Increase left margin to indent the title
  width: 30%;
  font-size: 1.5em;
  font-weight: 700;

  @media (max-width: 768px) {
    width: 100%; // Full width on mobile
    margin-left: 10px; // Adjust left margin for mobile
    margin-bottom: 30px; // Add some space between title and text on mobile
    text-align: left; // Align title to the left on mobile
  }
`;

const SectionText = styled.p`
  width: 65%;
  line-height: 1.6;
font-size: 1.1em;
  @media (max-width: 768px) {
    width: 100%; // Full width on mobile
    margin-left: 10px; // Align with the title's left margin on mobile
    text-align: left; // Align text to the left on mobile
    font-size: 1.0em;
  }
`;


const TeamContainer = styled.div`
  text-align: center;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const TeamTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 70px;
  margin-top: 150px;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 2em; // Slightly smaller font size on mobile
    margin-top: 100px;
    margin-bottom: 50px;
  }
`;

const MemberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;


const MemberImage = styled.img`
  width: 400px; // Adjust as needed
  height: 550px; // Adjust as needed
  border-radius: 10px; // Slightly rounded corners for a softer look
  margin-right: 20px;
  border: 4px solid #f0f0f0; // Solid border, change color as needed
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Adds a subtle shadow
  transition: transform 0.3s ease; // Smooth transition for hover effect
object-fit: cover;
  @media (max-width: 768px) {
    width: 75%; // Adjust width to be responsive
    height: auto; // Adjust height to maintain aspect ratio
    margin-bottom: 10px;
    margin-right: 0px;
  }

  &:hover {
    transform: scale(1.05); // Slightly enlarges the image on hover
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // Slightly larger shadow on hover for a dynamic effect
  }
`;

const MemberDescription = styled.div`
  text-align: left;
  max-width: 500px;
  margin-left: 60px;
  margin-right: 60px;

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 20px;
    text-align: center; // Center align the text on smaller screens
    font-size: 0.9em;
  }
`;

const MemberName = styled.h3`
  font-size: 1.5em; // Example font size, adjust as needed
  color: #333; // Example color, adjust as needed
  margin-bottom: 1.5em; // Example margin, adjust as needed
text-align: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 1em;
  }
`;

// Styled component for the member's bio
const MemberBio = styled.p`
  font-size: 1.2em; // Example font size, adjust as needed
  color: #666; // Example color, adjust as needed
  line-height: 1.8; // Example line height, adjust as needed
  @media (max-width: 768px) {
   text-align: left;
  
  }
`;

const FlippedMemberContainer = styled(MemberContainer)`
  flex-direction: row-reverse; // Flip the order of flex items
  margin-top: 150px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 80px; // Changed to column for mobile to match the MemberContainer
  }
`;


const About = () => {
    return (
      <Container>
        <Header>
          <HeaderText>OUR MISSION <br />AT SWIPE PROPERTIES</HeaderText>
          <HeaderTextMobile>SWIPE<br/>PROPERTIES<br/>MISSION</HeaderTextMobile>
          <SubHeaderText>Representing Dublin's Finest Properties, Showcasing Luxury and Quality.</SubHeaderText>
        </Header>
        <MissionTitle>Learn About Our Mission</MissionTitle>
      <MissionText>
      Swipe Property is transforming Dublin's rental market with quality properties ranging from studios to townhouses, using advanced online marketing and deep market insights to optimize tenant satisfaction and investment returns, while fostering strong relationships with landlords and investors.      </MissionText>
     

      <SectionConatiner>
      <SectionDivider />
      
      <Section>
        <SectionTitle>Innovative Property Solutions</SectionTitle>
        <SectionText>Swipe Property was established in Dublin to address the 
        growing need in Letting and Property Management. Experiencing a surge
         in demand for high-quality apartments and houses, our Property Rental Agency
          in Dublin is committed to meeting the evolving needs of our tenants.</SectionText>
      </Section>
      <SectionDivider />

      <Section>
        <SectionTitle>Expertise and Experience</SectionTitle>
        <SectionText>Having successfully managed Dublin’s largest independent Letting
         & Property Management company for nearly a decade, the team at Swipe Property
          brings unparalleled expertise to the Dublin property market. Our understanding 
          of property rental is both deep and broad, ensuring we provide top-notch service.</SectionText>
      </Section>
      <SectionDivider />

      <Section>
        <SectionTitle>Building Relationships</SectionTitle>
        <SectionText>Swipe Property, known for its professionalism and trustworthiness, is eager to connect with 
        interested landlords and manage property portfolios. We invite you to contact us and explore how our services
         can benefit you.</SectionText>
      </Section>
      <SectionDivider />

      <Section>
        <SectionTitle>Focused Market Insights</SectionTitle>
        <SectionText>Swipe Property is committed to sustainable property management.
         We encourage and implement eco-friendly practices in our properties, promoting
          energy efficiency and reducing environmental impact. This commitment not only 
          benefits the environment but also adds long-term value to the properties we manage.</SectionText>
      </Section>
      <SectionDivider />
      </SectionConatiner>


      <TeamContainer>
      <TeamTitle>Meet The Team</TeamTitle>
      <MemberContainer>
        <MemberImage src={Person2} alt="Team Member Name" />
        <MemberDescription>
          <MemberName>Colin Napper</MemberName>
          <MemberBio>Colin Napper, Managing Partner at Swipe Property, began his property career in 2004, founding and later selling one of Dublin's leading Letting agents in 2012. Transitioning his focus to property industry software development, Colin played a significant role in enhancing technology for lettings and estate agents. In 2015, he co-founded Swipe Property, aiming to redefine the lettings service in Dublin, particularly in the Short Term renting sector, by leveraging his vast industry experience and passion.</MemberBio>
        </MemberDescription>
      </MemberContainer>
      <FlippedMemberContainer>
          <MemberImage src={Person2} alt="Another Team Member Name" />
          <MemberDescription>
            <MemberName>ANDREAS RIHA</MemberName>
            <MemberBio>Andreas, Managing Partner at Swipe Property, has been a pivotal figure in the property industry since 2004, specializing in sales, marketing, and growth. His notable achievements include expanding a portfolio from the ground up to become Dublin's largest before its successful sale in 2012. Leveraging his extensive experience, Andreas developed innovative property management software for the lettings industry, founding Swipe Property to provide top-tier properties and exceptional service to landlords and tenants.   </MemberBio>
          </MemberDescription>
        </FlippedMemberContainer>
    </TeamContainer>
      
    
    </Container>
  )
}

export default About;