import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import SearchBox from './SearchBox';
import PropertyCard from './PropertyCard';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


// Styled components for layout
const Container = styled.div`
  text-align: center;
  margin: 5vw;
  margin-bottom: 0px;
  height: 1000px;
  @media (max-width: 1500px) {
    margin: 5vw;
    height: 800px;
  }
  @media (max-width: 850px) {
    margin: 5vw;
    height: 950px;
  }
  @media (max-width: 768px) {
    margin: 5vw;
    height: 1000px;
  }
  @media (max-width: 420px) {
    margin: 5vw;
    height: 900px;
  }
`;

const Title = styled.h1`
  color: #333;
  font-size: 2.5em;
  margin-bottom: 0.5em;
`;

const Subtitle = styled.h2`
  color: #666;
  font-size: 1.5em;
  margin-bottom: 1em;
`;

// Custom Arrow Styles
const Circle = styled.div`
  background-color: white; // White background
  border: 2px solid black; // Black border
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; // Adjust size as needed
  height: 30px; // Adjust size as needed
`;

const Arrow = styled.div`
  display: block;
  cursor: pointer;
  position: absolute;
  top: auto;
  bottom: -30px; // Positioning arrows above the dots
  z-index: 10;
  

  &:first-of-type { // Left Arrow
    left: calc(50% - 135px); // Adjust this value as needed
  }

  &:last-of-type { // Right Arrow
    left: calc(50% + 100px); // Adjust this value as needed
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

// Styled Button
// Styled Button
const StyledButton = styled.button`
  position: absolute;
  left: 50%; // Center horizontally
  transform: translateX(-50%); // Adjust for center alignment
  bottom: -100px; // Align with the arrows
  background-color: white; // White background color
  color: black; // Black text color
  border: 2px solid black; // Black border
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 29%;
  text-align: center;
  

  &:hover {
    background-color: black; // Black background on hover
    color: white; // White text on hover
  }

  @media (max-width: 770px) {
    width: 90%;
  }

  // Additional styling as needed
`;

const PropertyCardContainer = styled.div`
  // Your existing styles for the PropertyCard container
  padding-bottom: 30px; // Adjust this value to increase the space above the dots

  @media (max-width: 850px) {
    padding-bottom: 10px; 
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  max-width: 2500px; // Set the maximum width of the slider here
  margin: 0 auto; // Center the slider
  padding-bottom: 20px;
  @media (max-width: 850px) {
    padding-bottom: 0px;
  }
  
`;

const CustomNextArrow = (props) => (
  <Arrow onClick={props.onClick}>
    <Circle><IoIosArrowForward size={24} /></Circle> {/* Adjust icon size as needed */}
  </Arrow>
);

const CustomPrevArrow = (props) => (
  <Arrow onClick={props.onClick}>
    <Circle><IoIosArrowBack size={24} /></Circle> {/* Adjust icon size as needed */}
  </Arrow>
);



const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
      },
    }

  ]
};

const PropertyPreview = () => {
  const sliderRef = useRef();
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // return original string if it's empty
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  useEffect(() => {
    const s3Url = 'https://d32zbq7kdn6rwo.cloudfront.net/latest_response.json'; // Replace with your actual S3 URL
    fetch(s3Url)
      .then(response => response.json())
      .then(data => setProperties(data.ads)) // Assuming the response has an `ads` field
      .catch(error => console.error('Error fetching data:', error));
  }, []);


  const handlePropertySelect = (property) => {
    navigate(`/property/${property.ad_id}`);
  };


  return (
    <Container>
      <Title>Discover Your Perfect Home</Title>
      <Subtitle>Search for properties based on your criteria</Subtitle>
      <SearchBox />
      <Subtitle>Featured Properties</Subtitle>
      <SliderWrapper>
        <Slider ref={sliderRef} {...sliderSettings}>
          {properties.map((property, index) => (
            <PropertyCardContainer key={index} onClick={() => handlePropertySelect(property)}>
              <PropertyCard
                price={property.rent}
                address={property.address}
                imageSrc={property.images[0]}
                type={capitalizeFirstLetter(property.property_type)}
                bedroom={property.bedrooms}
                bathroom={property.bathrooms}
              />
            </PropertyCardContainer>
          ))}
        </Slider>
        <StyledButton>VIEW ALL</StyledButton>
      </SliderWrapper>
    </Container>
  );
};


export default PropertyPreview;