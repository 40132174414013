import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import SwipeLogo from '../Images/SwipeLogo.png'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Styled Navbar Container
const StyledNavbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    color: white;
    position: fixed; // Keep the navbar fixed at the top
    top: ${({ showNavbar }) => (showNavbar ? '0' : '-80px')}; // Hide or show based on showNavbar state
    transition: top 0.3s ease-in-out; // Smooth transition for sliding in and out
    width: 100%; // Full width
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0 10px;
    background-color: ${({ isScrolled, isPropertyPage }) => isPropertyPage ? 'white' : (isScrolled ? '#fff' : 'transparent')};
    color: ${({ isScrolled, isPropertyPage }) => isPropertyPage ? 'black' : (isScrolled ? 'black' : 'white')};
    border-color: ${({ isScrolled, isPropertyPage }) => isPropertyPage ? 'white' : (isScrolled ? 'black' : 'white')};
    box-shadow: ${({ isScrolled }) => (isScrolled ? '2px 0px 5px rgba(0, 0, 0, 0.5)' : '')};
    
`;


const SpecialLink = styled.a`
    margin: 0 10px;
    text-decoration: none;
    border: 2px solid; // Adjust border size as needed
    border-color: inherit;
    padding: 10px; // Add padding to create space around the text
    padding-left: 50px;
    padding-right: 50px;

    &:hover {
        background-color: inherit; // Optional, change on hover
        color: #333; // Optional, text color change on hover
    }
`;

const NavbarSection = styled.div`
    display: flex;
    align-items: center;
    
    // Adjust this to control the space between links
    a {
        color: inherit;
        margin: 0 10px;
        text-decoration: none;
    }
    @media (max-width: 1100px) {
        display: none;
      }
`;

// Left, Center, and Right sections can have specific styles if needed
const LeftSection = styled(NavbarSection)``;
const CenterSection = styled(NavbarSection)`
display: none;
@media (max-width: 1100px) {
    display: flex;
    flex: 1;
    justify-content: center;
  }`;
const RightSection = styled(NavbarSection)`
  margin-right: 20px;
`;


const MobileImage = styled.img`

    display: block;
    width: 30px;
    height: 50px;
    object-fit: cover;
    padding-left: 20px;
    padding-right: 5px;
    top: 0;
    left: 0;
  
`;

const HamburgerIcon = styled.div`
  cursor: pointer;
  display: none; // Hidden by default and will be shown in the media query for mobile

  @media (max-width: 1100px) {
    font-size: 40px;
    display: block; // Show hamburger icon on mobile
  }
`;

const Title = styled.h1`
  // ... existing styles
  font-size: 23px;
  @media (max-width: 1100px) {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -10px; // Adjust as needed
    padding: 0;
    text-align: center;
  }
`;

const NavbarLink = styled.a`
  margin: 0 10px;
  text-decoration: none;
  // ... existing styles

  @media (max-width: 1100px) {
    display: none; // Hide each link on mobile
  }
`;

const SlideOutMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 85%; // Width of the slide-out menu
  max-width: 350px;
  height: 100%;
  background-color: #fff;
  z-index: 5;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  box-shadow: ${({ isOpen }) => (isOpen ? '2px 0px 5px rgba(0, 0, 0, 0.5)' : '')};
  display: flex;
  flex-direction: column; // Stack children vertically
  align-items: center; // Center items horizontally
  padding: 20px 0; // Add some padding at the top and bottom
  
  ${Title}, ${NavbarLink}, ${SpecialLink} {
    color: black; // Set text color to black
    display: block; // Ensure each link is a block for full-width
    text-align: center; // Center the text
    width: 100%; // Full width links
    margin: 10px 0; // Add some margin for spacing
    padding: 10px 0; // Padding for better touch area
  }

  @media (min-width: 1101px) {
    display: none; // Ensures the menu is not displayed on larger screens
  }
`;

const MenuTitle = styled(Title)`
  color: black; // Override color for the title in the menu
  margin-bottom: 20px; // Add some spacing below the title
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;  // Adjust as necessary
  right: 20px; // Adjust as necessary
  background: none;
  border: none;
  color: black; // Choose color that contrasts with the menu's background
  font-size: 1.5em; // Adjust size as necessary
  cursor: pointer;
  padding: 0px;

  &:hover {
    color: #666; // Change color on hover if needed
  }
`;


// Modify the NavbarSection to hide its children links, not the container itself


    const Navbar = () => {
        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const menuRef = useRef();
        const [isScrolled, setIsScrolled] = useState(false);
        const [lastScrollY, setLastScrollY] = useState(0);
        const [showNavbar, setShowNavbar] = useState(true);
        const location = useLocation();
        const isPropertyPage = /^\/property\/\d+$/.test(location.pathname);

    
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < lastScrollY) {
              // Scrolling up
              setShowNavbar(true);
            } else if (currentScrollY > lastScrollY && currentScrollY > 80) {
              // Scrolling down
              setShowNavbar(false);
            }
            setLastScrollY(currentScrollY);
            setIsScrolled(currentScrollY > 80);
          };
          
    
        useEffect(() => {
            window.addEventListener('scroll', handleScroll);
    
            // Cleanup the event listener
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, [lastScrollY]);

  
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  
    // Event listener to close the menu if clicked outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsMenuOpen(false);
        }
      };
  
      // Add event listener when the menu is open
      if (isMenuOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      // Cleanup the event listener
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isMenuOpen]);

  return (
    <StyledNavbar isScrolled={isScrolled} showNavbar={showNavbar} isPropertyPage={isPropertyPage}>
      <HamburgerIcon onClick={toggleMenu}>
        {/* Icon or Label for Hamburger Menu */}
        ☰
      </HamburgerIcon>
      <LeftSection>
        {/* Left Section */}
        <MobileImage src={SwipeLogo} alt="Swipe Logo" />
        <Title>SWIPE<br/>PROPERTIES</Title>
      </LeftSection>
      <CenterSection>
        {/* Title only visible on mobile */}
        <Title>SWIPE<br/>PROPERTIES</Title>
      </CenterSection>
      <RightSection>
        {/* Navigation links that will be hidden on mobile */}
        <Link to="/">HOME</Link>
        <Link to="about">LIVE VIEWINGS</Link>
        <Link to="testimonials">TESTIMONIALS</Link>
        <Link to="/">OUR LEADS</Link>
        <Link to="/valuation">FREE EVALUATION</Link>
        <SpecialLink as={Link} to="/properties">PROPERTY SEARCH</SpecialLink>
      </RightSection>
      <SlideOutMenu isOpen={isMenuOpen} ref={menuRef}>
      <CloseButton onClick={toggleMenu}>X</CloseButton>

        <MenuTitle>SWIPE<br/>PROPERTIES</MenuTitle>
        <NavbarLink as={Link} to="/" onClick={toggleMenu}>HOME</NavbarLink>
        <NavbarLink as={Link} to="/properties" onClick={toggleMenu}>PROPERTY</NavbarLink>
        <NavbarLink as={Link} to="about" onClick={toggleMenu}>ABOUT US</NavbarLink>
        <NavbarLink as={Link} to="/services" onClick={toggleMenu}>SERVICES</NavbarLink>
        <NavbarLink as={Link} to="/testimonials" onClick={toggleMenu}>TESTIMONIALS</NavbarLink>
        <NavbarLink as={Link} to="/valuation" onClick={toggleMenu}>FREE EVALUATION</NavbarLink>
        <NavbarLink as={Link} to="/" onClick={toggleMenu}>VIDEO LISTINGS</NavbarLink>
        <NavbarLink as={Link} to="/blogs" onClick={toggleMenu}>BLOG</NavbarLink>
        <NavbarLink as={Link} to="/contact" onClick={toggleMenu}>CONTACT</NavbarLink>
       
      </SlideOutMenu>
    </StyledNavbar>
  );
};

export default Navbar;