import { GoogleMap, MarkerF, useLoadScript, InfoWindow, } from "@react-google-maps/api";
import React, { useState, useEffect, useMemo } from 'react';
import styled from "styled-components";

const Container = styled.div`
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    position: relative;
    margin-bottom: 100px;
`;

const MapContainer = styled.div`
  height: 100%;
 width: 100%;
`;

const MapComponent = ({ lat, lng, address, desc }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCt7AKCDkA_CJ5f3028PuTLj_EYB8w_Lb8"
  });
  const center = useMemo(() => ({ lat, lng }), [lat, lng]); 
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      // Delay the display of the InfoWindow by 1 second after the map has loaded
      const timer = setTimeout(() => {
        setShowInfoWindow(true);
      }, 500);

      // Cleanup the timer if the component unmounts before the timer fires
      return () => clearTimeout(timer);
    }
  }, [isLoaded]);

  let customMarker;

  const svgMarker = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="#ff0000" stroke="#ff0000"><g><path d="M62.79,29.172l-28-28C34.009,0.391,32.985,0,31.962,0s-2.047,0.391-2.828,1.172l-28,28 c-1.562,1.566-1.484,4.016,0.078,5.578c1.566,1.57,3.855,1.801,5.422,0.234L8,33.617V60c0,2.211,1.789,4,4,4h16V48h8v16h16 c2.211,0,4-1.789,4-4V33.695l1.195,1.195c1.562,1.562,3.949,1.422,5.516-0.141C64.274,33.188,64.356,30.734,62.79,29.172z"></path></g></svg>`;

  if (isLoaded) {
    const base64Svg = btoa(svgMarker);

    customMarker = {
      url: `data:image/svg+xml;base64,${base64Svg}`,
      scaledSize: new window.google.maps.Size(28, 28), // This needs the script to be loaded
      // Any other marker properties you want to set...
    };
  }

  return (
    <Container>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={center}
          zoom={17}
        >
          <MarkerF position={center} icon={customMarker} />
          {showInfoWindow && (
            <InfoWindow
              position={center}
              options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
            >
              <div>
                <h3>{address}</h3>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </Container>
  );
};

export default MapComponent;