import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios'; // if you are using axios
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ServiceCard from './ServiceCard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import house from '../Images/houseThing-svgrepo-com.svg';
import stock from '../Images/chart-decrease-svgrepo-com.svg'
import handshake from '../Images/handshake-svgrepo-com.svg' 
import dollar from '../Images/dollar-circle-svgrepo-com.svg'

const Container = styled.div`
  padding: 50px 0;
  text-align: center;
  padding-bottom: 100px;
  background-color: black;
  margin: auto;
  overflow: hidden;
  min-height: 70vh;
  @media (max-width: 768px) {
    height: auto;
  }
  color: white;
`;

const Section = styled.div`
  max-width: 1500px;
  margin: auto;
`;

const SectionTitle = styled.div`
  font-size: 1.7rem;
  margin-bottom: 5px;
  letter-spacing: 3px;
  

  @media (max-width: 768px) {
  font-size: 20px;
  padding-top: 30px;
  
  }
`;

const SectionSubtitleTitle = styled.div`
  font-size: 1rem;
  margin-bottom: 5px;
  letter-spacing: 2px;
  margin: auto;
  margin-top: 30px;

  margin-bottom: 80px;  
  max-width: 700px;

  @media (max-width: 768px) {
  font-size: 15px;
  padding-top: 30px;
  max-width: 80%;
  margin: auto;
  margin-bottom: 80px;
  }
`;

const TransparentButton = styled.button`
  padding: 13px 75px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 10px 60px;
  }
`;



const CardList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0;
  list-style: none;

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  & {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
`;


const Services = () => {
  const [products, setProducts] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoscroll: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          centerMode: false,
        }
      }
    ]
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Container>
    <Section>
      <SectionTitle>OUR SERVICES</SectionTitle>
      <SectionSubtitleTitle>Swipe Properties is a full-service real estate agency, offering a wide range of services to help you sell your property.</SectionSubtitleTitle>
      {isMobile ? (
        <Slider {...sliderSettings}>
        <ServiceCard
            title="PROPERTY MANAGEMENT"
            src1={house}
            description="At Swipe Properties, we're committed to securing the highest possible price for your property. Our team's profound local knowledge, honed over 20 years in the Dublin property market, enables us to provide realistic valuations that truly reflect your property's worth. We leverage online pre-viewings to tap into a global market, ensuring your property is showcased to a broad audience."
          />
          <ServiceCard
            title="VALUATIONS"
            src1={stock}
            description="At Swipe Properties, our expertise extends to offering comprehensive property valuations, a service honed through our deep-rooted experience in the Dublin property market over the last 20 years. Understanding the true value of your property is crucial, and our team is dedicated to providing realistic and accurate assessments that reflect the genuine worth of your property."
          />
          <ServiceCard
            title="LETTINGS"
            src1={handshake}
            description="At Swipe Properties, we excel in providing exceptional lettings services, backed by our 20 years of experience in the vibrant Dublin property market. Our team is dedicated to understanding the nuances of your property, ensuring that it is positioned perfectly in the market to attract the right tenants. We believe in making the lettings process seamless and rewarding for property owners."
          />
          <ServiceCard
            title="PROPERTY SALES"
            src1={dollar}
            description="At Swipe Properties, we pride ourselves on our exceptional expertise in property sales, a skill finely tuned over 20 years in the Dublin real estate market. Our team is dedicated to realizing the full potential of your property, ensuring it is presented and marketed to achieve maximum appeal and value. At Swipe Property we are professionals committed to achieving outstanding results."
          />
        </Slider>
      ) : (
        <CardList>
          <ServiceCard
            title="PROPERTY MANAGEMENT"
            src1={house}
            description="At Swipe Properties, we're committed to securing the highest possible price for your property. Our team's profound local knowledge, honed over 20 years in the Dublin property market, enables us to provide realistic valuations that truly reflect your property's worth. We leverage online pre-viewings to tap into a global market, ensuring your property is showcased to a broad audience."
          />
          <ServiceCard
            title="VALUATIONS"
            src1={stock}
            description="At Swipe Properties, our expertise extends to offering comprehensive property valuations, a service honed through our deep-rooted experience in the Dublin property market over the last 20 years. Understanding the true value of your property is crucial, and our team is dedicated to providing realistic and accurate assessments that reflect the genuine worth of your property."
          />
          <ServiceCard
            title="LETTINGS"
            src1={handshake}
            description="At Swipe Properties, we excel in providing exceptional lettings services, backed by our 20 years of experience in the vibrant Dublin property market. Our team is dedicated to understanding the nuances of your property, ensuring that it is positioned perfectly in the market to attract the right tenants. We believe in making the lettings process seamless and rewarding for property owners."
          />
          <ServiceCard
            title="PROPERTY SALES"
            src1={dollar}
            description="At Swipe Properties, we pride ourselves on our exceptional expertise in property sales, a skill finely tuned over 20 years in the Dublin real estate market. Our team is dedicated to realizing the full potential of your property, ensuring it is presented and marketed to achieve maximum appeal and value. At Swipe Property we are professionals committed to achieving outstanding results."
          />
        </CardList>
      )}
      </Section>
      <TransparentButton>LEARN MORE</TransparentButton>
    </Container>
  );
}

export default Services;