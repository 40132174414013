import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaComments, FaTimes, FaPaperPlane,FaComment } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { BiMessageDetail } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";

const IconContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    
    @media (max-width: 768px) {
        bottom: 7px;
    right: 7px;
    }
`;

const ChatHeader = styled.div`
    background-color:#d80000 ;
    color: white;
    display: flex;
    justify-content: center; // Centers children horizontally
    align-items: center; // Aligns children vertically in the center
    padding: 15px;
    position: relative;
    border-top-left-radius: 9px; // Add this line
    border-top-right-radius: 9px; // Add this line
    
    @media (max-width: 768px) {
        border-top-left-radius: 0px; // Add this line
    border-top-right-radius: 0px; 
    padding: 23px;
    }
`;

const ChatTitle = styled.div`
    font-size: 18px;
    text-align: center;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 10px; // Positions the close button on the right
`;

const MessageArea = styled.div`
  position: absolute;
  top: 51px; // Match the height of the ChatHeader
  bottom: 70px; // Match the height of the InputArea
  width: 100%;
  overflow-y: auto;
  
  display: flex;
  flex-direction: column;
  align-items: stretch; 

  @media (max-width: 768px) {
        bottom: 90px;
        top: 72px;
    }
`;

const ChatContainer = styled(motion.div)`
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 400px;
    min-height: 640px; // Minimum height to ensure container visibility
    max-height: 100vh; // Ensure it doesn't go beyond the viewport height
    background-color: white;
    z-index: 1001;
    display: ${props => props.isOpen ? 'block' : 'none'};
    border-radius: 10px; 
    overflow: hidden; // Ensures no internal content leaks outside the borders
    border: 1px solid grey;
    @media (max-width: 768px) {
        width: 100%; // Use a larger percentage of the screen for smaller devices
        bottom: 0;
        right: 0;
        height:100%; // Take full height on small devices
        border-radius: 0; // Optional: Remove border-radius for full-screen experience
    }
`;

const InputArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center; // Aligns items in the center vertically
  justify-content: space-between; // Spaces children evenly
  background-color: #f8f8f8;



  padding: 10px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-top: 1px solid #d0d0d0;
`;

const InputField = styled.textarea`
  flex-grow: 1;
  background-color: #f8f8f8;
  border: none;
  padding: 10px;
  margin-right: 5px;
  border-radius: 21px;
  font-size: 18px;
  resize: none; // Prevent manual resizing
  overflow: hidden; // Prevent scrollbar

  &:focus {
    outline: none;
  }
`;


const SendIcon = styled(BiRightArrow)`
  color: #d80000;
  cursor: pointer;
  // Removed position absolute and other positioning styles
`;







const Message = styled.div`
  background-color: ${props => props.sender === 'user' ? '#d80000' : '#e0e0e0'};
  color: ${props => props.sender === 'user' ? 'white' : 'black'};
  display: inline-block;
  max-width: calc(100% - 100px); // To make sure it doesn't overflow the parent container
  border-radius: 10px;
  padding: 15px 20px;
  margin: 10px 10px; // Adjusted margin for consistent spacing
  word-break: break-word;
  align-self: ${props => props.sender === 'user' ? 'flex-end' : 'flex-start'};
  text-align: left;

  @media (max-width: 768px) {
    font-size: 17px;
    }
`;

const LoadingMessage = styled(Message)`
  &:after {
    content: 'Loading...';
    animation: blink 1.2s infinite;
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
`;


const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   // Take full height of the container
  text-align: center;
`;

// EmptyStateLogo can be an SVG or an image
const EmptyStateIcon = styled(BiMessageDetail )`
  font-size: 60px; // Adjust size as needed
  color: black; // Adjust color as needed
  margin-bottom: 25px;
  margin-top: 100px;
`;


const Title = styled.div`
    margin-bottom: 10px;
    font-size: 18px;
`;

const SubTitle = styled.div`
font-size: 18px;
`;
const Container = styled.div`

`;

const StyledSVG = styled.svg`
  width: 40px; // Adjust width as needed
  height: 40px; // Adjust height as needed
  color: white; // Adjust color as needed (text-gray-800)
  // Add any additional styling you need
`;

const CircleContainer = styled.div`
  width: 60px; // Size of the circle
  height: 60px; // Size of the circle
  background-color:#d80000 ;
  border-radius: 20%; // Makes the div circular
  display: flex;
  align-items: center;
  justify-content: center;


  @media (max-width: 768px) {
    width: 50px; // Size of the circle
  height: 50px; 
    }
`;


const ChatIcon = () => {
    const [isChatOpen, setChatOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [userId, setUserId] = useState(uuidv4());
  
    const messageAreaRef = useRef(null);

    const chatVariants = {
        hidden: { 
          scale: 0.8, 
          opacity: 0 
        },
        visible: { 
          scale: 1, 
          opacity: 1,
          transition: { 
            type: "spring", 
            stiffness: 100 
          }
        },
        exit: { 
          scale: 0.8, 
          opacity: 0,
          transition: { 
            duration: 0.2 
          }
        }
      };

      
      
  
      const messagesEndRef = useRef(null); // This ref will be attached to the last message

    const scrollToEnd = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // useEffect to scroll to the end whenever messages change
    useEffect(() => {
        if (isChatOpen && messages.length > 0) {
            scrollToEnd();
        }
    }, [messages, isChatOpen]);

      
    const toggleChat = () => {
        const isMobileSize = window.matchMedia("(max-width: 768px)").matches;
      
        if (!isChatOpen) {
          if (isMobileSize) {
            // Prevent scrolling on the main page only if it's mobile size
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
            document.body.style.width = '100vw';
            document.body.style.position = 'fixed'; // Optional, based on your requirements
          }
        } else {
          // When closing the chat, reset the styles
          document.body.style.overflow = '';
          document.body.style.height = '';
          document.body.style.width = '';
          document.body.style.position = '';
        }
      
        setChatOpen(!isChatOpen);
      };
      
      
  
      const handleInputChange = (e) => {
        setInputValue(e.target.value);
      
        // Reset field height
        e.target.style.height = 'inherit';
      
        // Get the computed styles for the element
        const computed = window.getComputedStyle(e.target);
      
        // Calculate the height
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                     + e.target.scrollHeight
                     + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
      
        e.target.style.height = `${height}px`;
      };
      
  
    const sendMessage = async () => {
      if (inputValue.trim()) {
        const userMessage = inputValue;
        const loadingMessageId = uuidv4();

        setMessages(messages => [
          ...messages, 
          { text: userMessage, sender: 'user' },
          { id: loadingMessageId, text: '', sender: 'ai', loading: true }
        ]);

        setInputValue('');
        setLoading(true);
  
        try {
          const response = await fetch('https://8gfkmzwkxc.execute-api.eu-west-1.amazonaws.com/default/GPT', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: userId,
              message: userMessage,
            }),
          });
  
          const data = await response.json();
          setLoading(false);
  
          if (data.response) {
            setMessages(messages => messages.map(msg => 
              msg.id === loadingMessageId ? { ...msg, text: data.response, loading: false } : msg
            ));
          }
        } catch (error) {
          console.error('Error:', error);
          setLoading(false);
          setMessages(messages => messages.map(msg => 
            msg.id === loadingMessageId ? { ...msg, text: "Sorry, I couldn't process that.", loading: false } : msg
          ));
        }
      }
    };


    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault(); // Prevent the default action to avoid a new line when Enter is pressed
          sendMessage();
        }
      };

      const minHeight = 20; // You can adjust this value based on your design

  
    return (
      <Container>
        {!isChatOpen && (
            <IconContainer onClick={toggleChat}>
          <CircleContainer>
            <StyledSVG aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
              <path fillRule="evenodd" d="M4 3a1 1 0 0 0-1 1v8c0 .6.4 1 1 1h1v2a1 1 0 0 0 1.7.7L9.4 13H15c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1H4Z"/>
              <path fillRule="evenodd" d="M8 17.2h.1l2.1-2.2H15a3 3 0 0 0 3-3V8h2c.6 0 1 .4 1 1v8c0 .6-.4 1-1 1h-1v2a1 1 0 0 1-1.7.7L14.6 18H9a1 1 0 0 1-1-.8Z"/>
            </StyledSVG>
          </CircleContainer>
        </IconContainer>
        )}
        <ChatContainer
        isOpen={isChatOpen}
        variants={chatVariants}
        initial="hidden"
        animate={isChatOpen ? "visible" : "hidden"}
        exit="exit"
      >
          <ChatHeader>
            <ChatTitle>Messages</ChatTitle>
            <CloseButton onClick={toggleChat}>
              <FaTimes size={20} />
            </CloseButton>
          </ChatHeader>
          <MessageArea ref={messageAreaRef}>
  {messages.length === 0 ? (
    <EmptyState>
      <EmptyStateIcon />
      <Title>No Messages</Title>
      <SubTitle>Messages will be shown here</SubTitle>
    </EmptyState>
  ) : (
    messages.map((message, index) =>
      message.loading ? (
        <LoadingMessage key={index} sender={message.sender} />
      ) : (
        <Message key={index} sender={message.sender}>
          {message.text}
        </Message>
      )
    )
  )}
  <div ref={messagesEndRef} /> {/* This is the critical part */}
</MessageArea>
          <InputArea>
          <InputField
    value={inputValue}
    onChange={handleInputChange}
    onKeyPress={handleKeyPress}
    placeholder="Type Your Message..."
    rows="1" // Start with one row
  />
            {inputValue && (
              <SendIcon
                size={20}
                shouldDisplay={!!inputValue}
                onClick={sendMessage}
              />
            )}
          </InputArea>
        </ChatContainer>
      </Container>
    );
};
  
export default ChatIcon;