import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto; 
  margin-bottom: 40px;
  background-color: #f8f8f8;

  @media (max-width: 768px) {
    flex-direction: column; // Stack elements vertically on mobile
    align-items: center; // Center-align items for a neat look
  }
`;

const SearchInput = styled.input`
  padding: 10px;
  margin: 0 10px 0 0; // Adjust margin for spacing
  border: 1px solid #ccc;
  flex: 1;

  @media (max-width: 768px) {
    width: 92%; // Full width on mobile
    margin: 0 0 10px 0; // Adjust margin for mobile layout
  }
`;

const SelectWrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    width: 100%; // Full width to accommodate all selects on mobile
    justify-content: space-between; // Space out the selects evenly
    margin-bottom: 10px; // Margin for spacing from the search button
  }
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  -webkit-appearance: none; // Prevent iOS from overriding styles
  background-image: url('path-to-your-custom-dropdown-arrow-image'); // Custom arrow image
  background-position: right 10px top 50%; // Adjust this as needed
  background-repeat: no-repeat;
  background-size: 10px; // Adjust size of the arrow image

  @media (max-width: 768px) {
    margin-right: 0; // Remove margin on mobile
    flex: 1; // Flex grow to fill the wrapper
  }
`;




const SearchButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #000;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    width: 100%; // Full width on mobile
    margin-bottom: 10px; // Margin for spacing from the select wrapper
  }
`;




// SearchBox component
const SearchBox = () => {
    return (
      <SearchContainer>
        <SearchInput type="text" placeholder="Search our enquiries with area, postcode.." />
        <SelectWrapper>
          <Select>
            <option value="buyers">Buyers</option>
            <option value="renters">Renters</option>
            {/* Add more options as needed */}
          </Select>
          <Select>
            <option value="1bed">1 Bed</option>
            <option value="2bed">2 Bed</option>
            {/* Add more options as needed */}
          </Select>
          <Select>
            <option value="budget">Budget</option>
            {/* Add more options as needed */}
          </Select>
        </SelectWrapper>
        <SearchButton>SEARCH</SearchButton>
      </SearchContainer>
    );
  };

export default SearchBox;
