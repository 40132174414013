import React from 'react';
import styled from 'styled-components';
import BlogImage from '../Images/blog.jpg';
import { Link } from 'react-router-dom';


// Styled container for the entire blog card
const BlogCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%; // Use max-width instead of fixed width
  margin: 20px auto;
  position: relative;

  &:hover .image-overlay {
    display: block;
  }
  &:hover .learn-more-button {
    display: flex;
  }
`;

const ImageContainer = styled.div`
  width: 100%; // Use 100% width to fill the container
  height: 350px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
`;

// The overlay that appears on image hover
const ImageOverlay = styled.div`
  display: none; // Hidden by default
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black overlay
  z-index: 1; // Ensure it's above the image but below the button
  transition: all 0.3s ease; // Smooth transition for the overlay
`;

// Styling for the image itself
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; // Add transition for the transform property

  ${BlogCardContainer}:hover & {
    transform: scale(1.05); // Slightly increase the scale on hover
  }
`;

// Styling for the "READ MORE" button
const LearnMoreButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); // Center the button
  background-color: white; // White background
  color: black; // Black text
  border: 1px solid black; // Black border
  padding: 15px 22px; // Padding inside the button
  font-size: 0.8em; // Font size
  text-align: center; // Center text
  cursor: pointer; // Change cursor to pointer on hover
  font-weight: 700;
  z-index: 2; // Ensure it's above the tint
  opacity: 0; // Start fully transparent
  visibility: hidden; // Start hidden
  transition: all 0.3s ease; // Smooth transition for all properties

  ${BlogCardContainer}:hover & {
    opacity: 1; // Fully opaque when hovered
    visibility: visible; // Visible when hovered
  }

  &:hover {
    background-color: black; // Black background on hover
    color: white; // White text on hover
    border: 1px solid white; // White border on hover
  }
`;

// Styling for the blog title
const Title = styled.h2`
  margin: 0;
  font-size: 1.1em;
  font-weight: 700;
  margin-bottom: 15px;
`;

// Styling for the date text
const DateText = styled.p`
  margin: 5px 0;
  color: #666;
  font-size: 0.9em;
  margin-bottom: 15px;
`;

// Styling for the blog description
const Description = styled.p`
  max-width: 530px;
  word-wrap: break-word;
`;

// BlogCard component
// BlogCard component
const BlogCard = ({ post }) => {
  const date = new Date(post.date);

 
  const imageUrl = post._embedded?.['wp:featuredmedia']?.[0]?.source_url || 'path-to-default-image.jpg';

  return (
    <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <BlogCardContainer>
        <ImageContainer>
          <Image src={imageUrl} alt="Blog" />
          <ImageOverlay className="image-overlay" />
          <LearnMoreButton className="learn-more-button">READ MORE</LearnMoreButton>
        </ImageContainer>
        <Title dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
        <DateText>{date.toLocaleDateString()}</DateText>
        <Description dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
      </BlogCardContainer>
    </Link>
  );
};

export default BlogCard;
