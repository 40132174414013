import Home from './Pages/Home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Properties from './Pages/Properties';
import About from './Pages/About';
import TestimonialsPage from './Pages/TestimonialsPage';
import Services from './Pages/Services';
import Blogs from './Pages/Blogs';
import Contact from './Pages/Contact';
import Valuation from './Pages/Valuation';
import BlogsPage from './Pages/BlogsPage';
import PropertyPage from './Pages/PropertyPage';
import CustomerService from './Components/CustomerService';

function App() {
  return (


    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/testimonials" element={<TestimonialsPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/property/:propertyId" element={<PropertyPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/valuation" element={<Valuation />} />
        <Route path="/blog/:postId" element={<BlogsPage />} />
      </Routes>
      <CustomerService />
      <Footer />
    </Router>

  );
}

export default App;

