import React from 'react';
import styled from 'styled-components';

// Styles
const CardContainer = styled.div`
  display: flex;
  justify-content: center; // Centers the card horizontally
  padding: 10px; // Adds spacing around the card
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  text-align: left;
  
`;

const ImageContainer = styled.div`
  width: 100%;
  // Use a fixed height or a padding-top percentage to maintain aspect ratio
  padding-top: 56.25%; // 16:9 aspect ratio
  position: relative;
  object-fit: cover; 
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;// 'cover' will ensure the aspect ratio is maintained without stretching
`;

const Content = styled.div`
  padding: 15px; // Adds padding inside the card
`;

const Price = styled.p`
  font-size: 1.2vw; // Adjust font size accordingly
  letter-spacing: 2px;
  margin: 0;
  @media (max-width: 860px) {
    font-size: 3.5vw;
  }
  @media (max-width: 420px) {
    font-size: 4.5vw;
  }
`;

const Address = styled.p`
  font-size: 1vw; // Adjust font size accordingly
  color: #666;
  margin-top: 10px;
  @media (max-width: 860px) {
    font-size: 2.5vw;
  }
  @media (max-width: 420px) {
    font-size: 3.5vw;
  }
`;

const Description = styled.p`
  font-size: 0.9vw; // Adjust font size accordingly
  color: #333;
  margin-top: 5px;
  @media (max-width: 860px) {
    font-size: 2.5vw;
  }
  @media (max-width: 420px) {
    font-size: 3.5vw;
  }
`;

// Component
const PropertyCard = ({ price, address, imageSrc, type, bedroom, bathroom }) => {
  return (
    <CardContainer>
      <Card>
        <ImageContainer>
          <Image src={imageSrc} alt="Property Interior" />
        </ImageContainer>
        <Content>
          <Price>${price} / Month</Price>
          <Address>{address}</Address>
          <Description>{type} | {bedroom} Bedrooms | {bathroom} Bathrooms</Description>
        </Content>
      </Card>
    </CardContainer>
  );
}

export default PropertyCard;
