import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import ReviewCard from '../Components/ReviewCard';
import axios from 'axios';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Office from '../Images/handshake.jpg';


import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Container = styled.div`
  margin-bottom: 50px;
`;

const Header = styled.div`
  height: 76vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${Office});
    background-size: cover;
    background-position: 50% 50%; // Default position
    filter: blur(0px); // Apply a slight blur to the background image
    z-index: -1; // Set to -1 so it's behind the text

    // For desktop
    @media (min-width: 769px) {
      background-position:10% 30%; // Adjust these values for desktop
    }

    // For mobile
    @media (max-width: 768px) {
      background-position: 65% 50%; // Adjust these values for mobile
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); // Black tint with 50% opacity
    z-index: -1; // Set to -1 so it's behind the text
  }
`;

const HeaderText = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;
  color: white;
  font-size: 3em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none; // hide this on mobile
  }
`;

const HeaderTextMobile = styled.div`
  display: none; // hide this on larger screens
  position: absolute;
  bottom: 80px;
  left: 30px;
  color: white;
  font-size: 2em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: block; // show this on mobile
    bottom: 180px;
  left: 20px;
  font-size: 2.2em;
  line-height: 120%;
  }
`;

const SubHeaderText = styled.div`
  position: absolute;
  bottom: 40px;
  left: 100px;
  color: white;
  font-size: 1.05em;
  z-index: 2;
letter-spacing: 2px;
line-height: 180%;
  @media (max-width: 768px) {
    font-size: 1em; // smaller font size for mobile
    left: 20px; // adjust position for mobile
    bottom: 60px;
    padding-right: 1px;
    
  }
`;

const Testimonials = styled.div`
 
`;





const Title = styled.div`
text-align: center;
font-size: 3em;
font-weight: 700;
margin-top: 60px;
margin-bottom: 20px;
@media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 2em;
    margin-top: 60px;
  }
`;

const SubTitle = styled.div`
color: #666;
text-align: center;
margin-bottom: 50px;
font-size: 1.3em;
@media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 35px;
    margin-bottom: 20px;
    font-size: 1.2em;
  }
`;

const Arrow = styled.div`
  display: block;
  cursor: pointer;
  position: absolute;
  top: auto;
  bottom: -30px; // Positioning arrows above the dots
  z-index: 10;
  

  &:first-of-type { // Left Arrow
    left: calc(50% - 135px); // Adjust this value as needed
  }

  &:last-of-type { // Right Arrow
    left: calc(50% + 110px); // Adjust this value as needed
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const CustomNextArrow = (props) => (
  <Arrow onClick={props.onClick}>
   <IoIosArrowForward size={24} /> {/* Adjust icon size as needed */}
  </Arrow>
);

const CustomPrevArrow = (props) => (
  <Arrow onClick={props.onClick}>
 <IoIosArrowBack size={24} /> {/* Adjust icon size as needed */}
  </Arrow>
);


const PropertyCardContainer = styled.div`
  // Your existing styles for the PropertyCard container
  padding-bottom: 30px; // Adjust this value to increase the space above the dots

  @media (max-width: 850px) {
  
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  max-width: 2000px; // Set the maximum width of the slider here
  margin: 0 auto; // Center the slider
  padding-bottom: 20px;

  @media (max-width: 1300px) {
    max-width: 1000px;
  }
  @media (max-width: 850px) {
    
  }
  
`;

const TestimonialsPage = () => {
  const sliderRef = useRef();
  const [reviews, setReviews] = useState([]);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default to 4 slides per view
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    autoplay: true, // Enable auto-play
    autoplaySpeed: 5000, 
    responsive: [
      {
        breakpoint: 1700, // When the screen size is <= 1300px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300, // When the screen size is <= 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850, // When the screen size is <= 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
         
        },
      },
    ],
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get('https://ifdldjllpi.execute-api.eu-west-1.amazonaws.com/default/GoogleReviews');
        setReviews(response.data); // Assuming the API returns an array of reviews
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);


  return (
    <Container>
      <Header>
        <HeaderText>SHOWCASE <br />OUR TESTIMONIALS</HeaderText>
        <HeaderTextMobile>OUR<br/>TESTIMONIALS<br/></HeaderTextMobile>
        <SubHeaderText>Building Exceptional Properties Through Trusted Client Partnerships and Insight.</SubHeaderText>
      </Header>
      <Testimonials>
      <Title>What Our Clients Say</Title>
      <SubTitle>Insights on what our Clients say about us.</SubTitle>
      <SliderWrapper>
        <Slider ref={sliderRef} {...sliderSettings}>
          {reviews.map((review, index) => (
            <PropertyCardContainer key={index}>
              <ReviewCard
                name={review.author_name}
                profilePicture={review.profile_photo_url}
                description={review.text}
              />
            </PropertyCardContainer>
          ))}
        </Slider>
      </SliderWrapper>
      </Testimonials>
    </Container>
  )
}

export default TestimonialsPage