
//property sales
import Knowledge from '../Images/knowledge.png';
import Human from '../Images/marketing.png';
import Laptop from '../Images/laptop.png';
import Handshake from '../Images/handshake.png';

// lettings
import Glass from '../Images/glass.png';
import Scale from '../Images/scale.png';
import House from '../Images/house.png';


//property management 
import Manage from '../Images/manage.png';
import Clipboard from '../Images/clipboard.png';

import Bar from '../Images/bar.png';


//valuations
import Brain from '../Images/brain.png';
import Graph from '../Images/graph.png';

import Free from '../Images/free.png';


export const propertySalesSections = [
    {
        title: "Local Knowledge",
        text: "With over 20 years operating in the Dublin property market, the team at Swipe property have extensive local knowledge and provide realistic and achievable valuations.",
        logo: Knowledge,
        altText: "Local Knowledge"
    },
    {
        title: "Marketing",
        text: "We market your property across all major property portals including Daft.ie & Myhome.ie. We also produce an Online video tour and promote extensively on social media and our website.",
        logo: Human,
        altText: "Marketing"
    },
    {
        title: "Online Pre Viewings",
        text: "Our team is ready to conduct online pre-viewings with a global market, not just local buyers, helping bring the masses into your living room.",
        logo: Laptop,
        altText: "Online Pre Viewings"
    },
    {
        title: "Negotiations",
        text: "Our team of property experts are trained to negotiate the best price for your property, taking into account the different buyer personas and timescales to get the deal done.",
        logo: Handshake,
        altText: "Negotiations"
    }
    // ... Add other sections here if needed
];

export const lettingsSections = [
    {
        title: "Tenant Referencing",
        text: "Letting out properties can be a hassle for some landlords. Let the experts help choose the best tenants for your property. All our tenants are referenced to a high standard.",
        logo: Glass,
        altText: "Tenant Referencing"
    },
    {
        title: "Compliance & Legal",
        text: "From drafting compliant Part 4 or fixed term letting agreements with the tenants to registering your tenants to the PRTB, we at Swipe will make sure you stay a compliant landlord.",
        logo: Scale,
        altText: "Compliance & Legal"
    },
    {
        title: "Utilities",
        text: "Change and close over old electric and gas accounts into the incoming tenants' names, avoiding any problems with utility companies.",
        logo: House,
        altText: "Utilities"
    },
    {
        title: "Negotiations",
        text: "Our team of property experts are trained to negotiate the best price for your property, taking into account the different buyer personas and timescales to get the deal done.",
        logo: Handshake,
        altText: "Negotiations"
    }
    // ... Add other sections here if needed
];

export const propertyManagementSections = [
    {
        title: "Rent Management",
        text: "Managing rental payments can be a headache for some landlords, our software automatically monitors rent due dates and reconciles to landlords accounts each month promptly.",
        logo: Manage, // Optional: Use only if you have a specific logo for this subsection
        altText: "Rent Management"
    },
    {
        title: "Digital Inventory",
        text: "Does your property have a digital inventory itemising all the contents and conditions of a property. As part of our property management service we prepare a 70 page report helping minimise disputes upon check out.",
        logo: Clipboard, // Optional
        altText: "Digital Inventory"
    },
    {
        title: "Inspections",
        text: "Property inspections are an essential element of good property management and is important to ensure a regular inspections are carried out to ensure the let property is returned in the best possible condition.",
        logo: Glass, // Optional
        altText: "Inspections"
    },
    {
        title: "Accounts Management",
        text: "Our finance department prepare monthly landlord financial statements and remitting the balance to landlords accounts. We also offer landlords tax advice.",
        logo: Bar, // Optional
        altText: "Accounts Management"
    }
    // ... Add more subsections if needed
];


export const valuationsSections = [
    {
        title: "Local Knowledge",
        text: "Having in-depth local knowledge enables Swipe Property to compile accurate valuations which helps the sale and letting process run smoothly.",
        logo: Glass, // Optional: Use only if you have a specific logo for this subsection
        altText: "Local Knowledge"
    },
    {
        title: "Expertise",
        text: "Using our extensive property expertise our aim is to provide a cost-effective comprehensive valuation service to vendors and landlords.",
        logo: Brain, // Optional
        altText: "Expertise"
    },
    {
        title: "Market Appraisal",
        text: "If you are thinking of or selling or letting your property in the Dublin area and would like a clearer picture of how much your property might be worth, one of our agents will be happy to arrange a full market appraisal of your property.",
        logo: Graph, // Optional
        altText: "Market Appraisal"
    },
    {
        title: "Free Appraisal",
        text: "To request a free market appraisal or a formal valuation of your property, please contact a member of the Swipe Property team.",
        logo: Free, // Optional
        altText: "Free Appraisal"
    }
    // ... Add more subsections if needed
];
