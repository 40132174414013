import React from 'react';
import styled, { keyframes } from 'styled-components';
import houses from '../Images/Black-White-Houses.jpg'

const fade = keyframes`
  0%, 100% { opacity: 0; }
  25%, 75% { opacity: 1; }
`;

const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
`;


const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background: url('path_to_your_image.jpg') no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;

  z-index: -2;

  // Fix the background image
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('https://burczakproperties.co.za/images/slider/01.jpg') no-repeat center center;
    background-size: cover;
    background-attachment: fixed; // This fixes the background
    opacity: 2; // Adjust as necessary for darkening
  }
`;


const Title = styled.h2`
  color: white;
  font-size: 3em;
  text-align: center;
  margin-bottom: 50px; // Adjust as needed

  &:after {
    content: '';
    display: block;
    width: 30%;
    height: 2px;
    background-color: white;
    margin: 0 auto;
    position: relative;
    bottom: -25px;
  }
  @media (max-width: 1000px) {
    font-size: 2em;
    padding: 10px 60px;
  }
`;

const TestimonialText = styled.div`
  color: white;
  font-size: 1.5em;
  font-style: italic;
  text-align: center;
  max-width: 800px;
  height: 100px;
  margin-bottom: 30px; // Spacing before the button
  animation: ${fade} 5s infinite;
  @media (max-width: 1000px) {
    font-size: 1em;
    padding: 10px 60px;
  }
`;


const TransparentButton = styled.button`
  padding: 13px 75px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 10px 60px;
  }
`;



const MediaTint = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); 
  z-index: -1; 
`;

const testimonials = [
    "\"Swipe Properties has been handling the letting of my property in Northern Cross for many years, and I can't recommend them enough.\"",
    "\"Colin from Swipe Properties let and manages my properties, giving me access to an online account so I can check on information as I need. Highly recommended.\"",
  ];
  

  const Testimonials = () => {
    const [activeIndex, setActiveIndex] = React.useState(0);
  
    React.useEffect(() => {
      const interval = setInterval(() => {
        setActiveIndex((current) => (current + 1) % testimonials.length);
      }, 5000); // Change every 5 seconds
  
      return () => clearInterval(interval);
    }, []);
  
    return (
        <TestimonialsContainer>
          <BackgroundImage />
          <MediaTint/>
          <Title>Customer Testimonials</Title>
          <TestimonialText>{testimonials[activeIndex]}</TestimonialText>
          <TransparentButton>About Us</TransparentButton>
        </TestimonialsContainer>
      );
    };
  
  export default Testimonials;
  