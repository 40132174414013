import React from 'react'
import styled from 'styled-components';
import Header from '../Components/Header';
import PropertyPreview from '../Components/PropertyPreview';
import Services from '../Components/Services';
import Testimonials from '../Components/Testimonials';
import BriefAbout from '../Components/BriefAboutUs';

const Container = styled.button`
  
`;


const Home = () => {
  return (
    <div>
    <Header />
    <PropertyPreview/>
    <Services/>
    <Testimonials/>
    <BriefAbout/>
    </div>
  )
}

export default Home