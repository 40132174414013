import React from 'react';
import styled from 'styled-components';
import worker from '../Images/worker.jpg'; 
import wave2 from '../Images/svg-4.png'; 
import quotation from '../Images/quote.svg'; 

// Styled components
const Card = styled.div`
  position: relative;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  padding: 30px;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  overflow: hidden; 
 
  
  
  
  @media (max-width: 430px) {
    max-width: 320px;
  }
  @media (max-width: 390px) {
    max-width: 275px;
  }
 
`;

const Image = styled.img`
  width: 100%; // Fill the parent div
  height: 100%; // Maintain aspect ratio for a circle
  border-radius: 50%; // Maintain the circular shape
  object-fit: cover; // Cover the area without stretching
  object-position: center top; // Adjust the position of the image
  // Other styles...
`;

const Name = styled.h3`
  position: relative; // Position relative will keep it within the flex container
  color: #fff;
  font-weight: 600;
  z-index: 2;
  align-self: center; // Center the name inside the card
  margin-top: 30px; // Increase space above the name
  margin-bottom: 65px; // Increase space below the name if needed
`;

const Description = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  color: #666;
  text-align: center; // Center align the description
  margin: 0 0 30px 0;
  margin-top: 70px;
  margin-bottom: 70px;

  @media (max-width: 430px) {
    margin-top: 60px;
  margin-bottom: 50px;
  font-size: 1.1em;
  }
`;



const ProfileWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 3; // Ensure this is above the wave and the name
  align-self: center; // Center within the flex container
  background: white;
  border: 3px solid #FFF;
  margin-bottom: -20px;
   // Pull the image down to overlap the wave slightly
`;

const Wave = styled.div`
  position: absolute;
  bottom: 0;
  width: 600px;
  z-index: 1; // Below the profile image
  overflow: hidden; // Ensure the SVG doesn't overlap content above
  transform: scaleY(1.2);

  // Adding a background color that matches the bottom of the wave image
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 50%, // Transparent at the top
    #ABB8C3 50% // The desired color filling the bottom
  );
`;

// Rest of your component...



const QuotationMark = styled.img`
  position: absolute;
  top: 30px; // Adjust as needed for exact positioning
  left: 25px; // Adjust as needed for exact positioning
  width:30px; // Set the size of the quotation mark image
  height: auto; // Maintain aspect ratio
  z-index: 10; // Ensure it's above other content
`;


const ReviewCard = ({ name, profilePicture, description }) => (
  <Card>
   <QuotationMark src={quotation} alt="Quotation mark" />
<Description>{description}</Description>
<ProfileWrapper>
      <Image src={profilePicture} alt="Profile picture" />
    </ProfileWrapper>
    <Name>{name}</Name>
    <Wave>
      <img src={wave2} alt="Wave design" style={{ width: '100%', height: 'auto' }} />
    </Wave>
  
  </Card>
);

export default ReviewCard;
