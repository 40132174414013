import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import RealEstateVideo from '../Images/RealEstate.mp4';
import FallbackImg from '../Images/home2.jpeg';

const Container = styled.div`
  top: 0;
  left: 0;
  
  height: 100vh;
  overflow: hidden;
  
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileImage = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 45% 10%; /* Adjust these values */
    position: absolute;
    top: 0;
    left: 0;
  }
`;



const MediaTint = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65); 
  z-index: 1; 
`;

const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 2; // Above the tint
  padding: 0 20px; // Add padding for smaller screens

  @media (max-width: 768px) {
    width: 80%; // Adjust width for smaller screens
  }
`;


const TitleText = styled.h1`
  margin-top: 15px ;
  margin-bottom: 15px ;
  font-size: 4em; // Adjust size as needed
  letter-spacing: 2px;

  @media (max-width: 768px) {
    font-size: 2.5em; // Smaller size for mobile
  }
`;

const NormalText = styled.p`
  
  font-size: 1em; // Adjust size as needed

  @media (max-width: 768px) {
    font-size: 0.9em; // Smaller size for mobile
  }
`;


const TransparentButton = styled.button`
  margin-top: 30px; // Spacing above the button
  padding: 13px 75px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 0.9em; // Smaller button text for mobile
    padding: 10px 60px; // Smaller padding for mobile
  }
`;


const Header = () => {
  const [videoError, setVideoError] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 768;

  return (
    <Container>
      {isMobile ? (
        <>
          <MobileImage src={FallbackImg} alt="Fallback Image" />
          <MediaTint />
          <TextContainer>
            <NormalText>Property for All</NormalText>
            <TitleText>SWIPE PROPERTIES</TitleText>
            <NormalText>PERSISTENCE ALWAYS BEATS RESISTANCE</NormalText>
            <TransparentButton>Explore</TransparentButton>
          </TextContainer>
        </>
      ) : (
        <>
          <StyledVideo
            autoPlay
            loop
            muted
            onError={() => setVideoError(true)}
          >
            <source src={RealEstateVideo} type="video/mp4" />
          </StyledVideo>
          <MediaTint />
          <TextContainer>
            <NormalText>Property for All</NormalText>
            <TitleText>SWIPE PROPERTIES</TitleText>
            <NormalText>PERSISTENCE ALWAYS BEATS RESISTANCE</NormalText>
           
            <TransparentButton>Explore</TransparentButton>
          </TextContainer>
        </>
      )}
    </Container>
  );
};

export default Header;