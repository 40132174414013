import React from 'react';
import styled from 'styled-components';
import Office from '../Images/Services.jpg';

import { propertySalesSections, lettingsSections, valuationsSections, propertyManagementSections } from '../Data/SectionsData';






const Container = styled.div`
  margin-bottom: 50px;
`;

const Header = styled.div`
  height: 76vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${Office});
    background-size: cover;
    background-position: center;
    filter: blur(0px); // Apply a slight blur to the background image
    z-index: -1; // Set to -1 so it's behind the text
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); // Black tint with 50% opacity
    z-index: -1; // Set to -1 so it's behind the text
  }
`;

const HeaderText = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;
  color: white;
  font-size: 3em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none; // hide this on mobile
  }
`;

const HeaderTextMobile = styled.div`
  display: none; // hide this on larger screens
  position: absolute;
  bottom: 80px;
  left: 30px;
  color: white;
  font-size: 2em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: block; // show this on mobile
    bottom: 180px;
  left: 20px;
  font-size: 2.2em;
  line-height: 120%;
  }
`;

const SubHeaderText = styled.div`
  position: absolute;
  bottom: 40px;
  left: 100px;
  color: white;
  font-size: 1.05em;
  z-index: 2;
letter-spacing: 2px;
line-height: 180%;
  @media (max-width: 768px) {
    font-size: 1em; // smaller font size for mobile
    left: 20px; // adjust position for mobile
    bottom: 60px;
    padding-right: 1px;
    
  }
`;

const ServiceTitle = styled.div`
    text-align: center;
    font-size: 2.5em;
    font-family: 'Syncopate', sans-serif;
    margin-top: 70px;
  margin-right: 5px;
  margin-left: 5px;
    @media (max-width: 768px) {
    font-size: 1.5em; // smaller font size for mobile
   
    
  }
`
const SubText = styled.div`
    
    color: #666;
text-align: center;
margin-top: 15px;
margin-bottom: 70px;
font-size: 1.1em;
padding-left: 20px;
    padding-right: 20px;
@media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    
  }
`;


const SectionConatiner = styled.div`
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  position: relative; // Add this to position the pseudo-element

  &::before {
    content: ''; // Required for pseudo-elements
    position: absolute;
    top: 0;
    left: 50%; // Center the pseudo-element
    transform: translateX(-50%); // Center the pseudo-element
    width: 100%;
    max-width: 1500px; // Set the max width of the pseudo-element
    height: 1px;
    background-color: grey; // Your border color
  }

  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
  }
`;




const SectionDivider = styled.hr`
  border-top: 1px solid grey; // Use border-top instead of background-color
  border-bottom: none; // Remove bottom border if any
  height: 0; // Set height to 0 since we are using border-top
  margin: 20px auto; // Centers the divider and adds vertical space
  max-width: 1500px; // Adjust this value as needed
`;


const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  max-width: 1500px; // Set the max width to match the SectionDivider
  margin: auto; // Center the section
  &:first-child {
    padding-top: 30px; // Adjust this value to increase the space below the top border
  }
  @media (max-width: 768px) {
    flex-direction: column; // Stack the items vertically on mobile
    align-items: flex-start; // Align items to the start of the container
  }
`;

const SectionTitle = styled.div`
  display: flex; // Use flexbox
  align-items: center; // Align items vertically
  margin-left: 25px;
  width: 30%;
  font-size: 1.5em;
  font-weight: 700;

  @media (max-width: 768px) {
    width: 100%;
    margin-left:5px;
    margin-bottom: 30px;
    text-align: left;
  }

 
`;

const SectionText = styled.p`
  width: 65%;
  line-height: 1.6;

  @media (max-width: 768px) {
    width: 100%; // Full width on mobile
    margin-left: 5px; // Align with the title's left margin on mobile
    text-align: left; // Align text to the left on mobile
  }
`;

const Logo = styled.img`
  width: 30px; // Adjust size as needed
  height: 30px;
  margin-right: 20px; 
  object-fit: contain;
  @media (max-width: 768px) {
    margin-right: 20px; 
  }
`;

const variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
};

const Services = () => {
    
  
    return (
      <Container>
        <Header>
        <HeaderText>LEARN MORE <br />ABOUT SWIPE PROPERTIES</HeaderText>
          <HeaderTextMobile>ABOUT<br/>SWIPE<br/>PROPERTIES</HeaderTextMobile>
          <SubHeaderText>Your Premier Choice for Property Services in Dublin's Market.</SubHeaderText>
        </Header>
  
       
        <ServiceTitle>Property Sales</ServiceTitle>
<SubText>Our aim is to achieve the highest price for your property</SubText>
<SectionConatiner>
  {propertySalesSections.map((section, index) => (
    <React.Fragment key={index}>
      <Section>
        <SectionTitle>
          <Logo src={section.logo} alt={section.altText} />
          {section.title}
        </SectionTitle>
        <SectionText>{section.text}</SectionText>
      </Section>
      <SectionDivider />
    </React.Fragment>
  ))}
</SectionConatiner>


<ServiceTitle>LETTINGS</ServiceTitle>
<SubText>Swipe Property rented over 70 properties in 2016 achieving top rents for landlords & Investors</SubText>
<SectionConatiner>
  {lettingsSections.map((section, index) => (
    <React.Fragment key={index}>
      <Section>
        <SectionTitle>
          <Logo src={section.logo} alt={section.altText} />
          {section.title}
        </SectionTitle>
        <SectionText>{section.text}</SectionText>
      </Section>
      <SectionDivider />
    </React.Fragment>
  ))}
</SectionConatiner>


 <ServiceTitle>Valuations</ServiceTitle>
            <SubText>We specialise in providing valuation and property advice on both sales and property lettings.</SubText>
            <SectionConatiner>
                {valuationsSections.map((section, index) => (
                    <React.Fragment key={index}>
                        <Section>
                            <SectionTitle>
                                <Logo src={section.logo} alt={section.altText} />
                                {section.title}
                            </SectionTitle>
                            <SectionText>{section.text}</SectionText>
                        </Section>
                        <SectionDivider />
                    </React.Fragment>
                ))}
            </SectionConatiner>

            
            <ServiceTitle>Property Management</ServiceTitle>
            <SubText>We have simplified the Property management process so you can rest assured your property is in safe hands.</SubText>
            <SectionConatiner>
                {propertyManagementSections.map((section, index) => (
                    <React.Fragment key={index}>
                        <Section>
                            <SectionTitle>
                                <Logo src={section.logo} alt={section.altText} />
                                {section.title}
                            </SectionTitle>
                            <SectionText>{section.text}</SectionText>
                        </Section>
                        <SectionDivider />
                    </React.Fragment>
                ))}
            </SectionConatiner>
      </Container>
    );
  };
  
  export default Services;