import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import BlogCard from '../Components/BlogCard';
import Office from '../Images/Laptop.jpg';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import WordpressAPI from '../API/WordpressAPI';


const Container = styled.div`
  margin-bottom: 50px;

  
`;

const Header = styled.div`
  height: 76vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${Office});
    background-size: cover;
    background-position: center;
    filter: blur(0px);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
  }
`;

const HeaderText = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;
  color: white;
  font-size: 3em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderTextMobile = styled.div`
  display: none;
  position: absolute;
  bottom: 80px;
  left: 30px;
  color: white;
  font-size: 2em;
  font-family: 'Syncopate', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 2;

  @media (max-width: 768px) {
    display: block;
    bottom: 180px;
    left: 20px;
    font-size: 2.2em;
    line-height: 120%;
  }
`;

const SubHeaderText = styled.div`
  position: absolute;
  bottom: 40px;
  left: 100px;
  color: white;
  font-size: 1.05em;
  z-index: 2;
  letter-spacing: 2px;
  line-height: 180%;
  @media (max-width: 768px) {
    font-size: 1em;
    left: 20px;
    bottom: 60px;
    padding-right: 1px;
  }
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, max-content)); // Default to 2 columns
  justify-content: center;
  align-items: start;
  grid-gap: 15px  50px;
  padding: 20px;
  padding-top: 60px;
  opacity: ${props => props.isVisible ? 1 : 0};
  transform: scale(${props => props.isVisible ? 1 : 0.95});
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; 

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, max-content)); // 1 column on mobile
    padding: 40px; // Adjust padding for mobile
    grid-gap: 5px  50px;
   padding: 15px;
    padding-top: 40px;
   
  }
`;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  padding: 12px 16px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%; // Make it circular
  border: 1px solid black; // Add a border
  background-color: ${props => props.isActive ? 'black' : 'white'}; // Black background for active page
  color: ${props => props.isActive ? 'white' : 'black'}; // White text for active page, black for others
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.isActive ? 'black' : '#eee'}; // Keep black on hover for active, light gray for others
  }
`;

// The rest of your Blogs component remains the same



const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${props => props.disabled ? '#666' : 'black'}; // Grey out if disabled

  &:hover {
    background-color: ${props => props.disabled ? 'none' : '#eee'};
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;



const Blogs = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isVisible, setIsVisible] = useState(true);
    const blogGridRef = useRef(null);
    const [posts, setPosts] = useState([]);
    const itemsPerPage = 8;
    const totalItems = posts.length;
    const pages = Math.ceil(totalItems / itemsPerPage);

    

    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const response = await WordpressAPI.get('/posts?_embed');
          setPosts(response.data);
        } catch (error) {
          console.error('Error fetching posts:', error);
          // Handle the error appropriately
        }
      };
    
      fetchPosts();
    }, []);
    


    const getPaginationNumbers = () => {
      let start = Math.max(1, currentPage - 1);
      let end = Math.min(pages, start + 2);
  
      if (currentPage === 1) {
        start = 1;
        end = Math.min(pages, 3);
      } else if (currentPage === pages) {
        start = Math.max(1, pages - 2);
        end = pages;
      }
  
      return Array.from({ length: (end - start) + 1 }, (_, i) => start + i);
    };
  
    const changePage = (pageNumber) => {
      setIsVisible(false);
  
      setTimeout(() => {
        setCurrentPage(pageNumber);
        setIsVisible(true);
  
        if (blogGridRef.current) {
          const offset = 100;
          const blogGridPosition = blogGridRef.current.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: blogGridPosition - offset, behavior: 'smooth' });
        }
      }, 300);
    };
  
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedItems = Array.from({ length: totalItems }).slice(startIndex, startIndex + itemsPerPage);
  
    return (
      <Container>
        <Header>
          <HeaderText>OUR BLOGS</HeaderText>
          <HeaderTextMobile>ABOUT<br/>SWIPE<br/>PROPERTIES</HeaderTextMobile>
          <SubHeaderText>Insights and Updates from the Heart of Dublin's Property Scene.</SubHeaderText>
        </Header>
        <BlogGrid ref={blogGridRef} isVisible={isVisible}>
          {posts.slice(startIndex, startIndex + itemsPerPage).map((post) => (
            <BlogCard key={post.id} post={post} />
          ))}
        </BlogGrid>
        <PaginationContainer>
          <ArrowButton 
            onClick={() => changePage(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
          >
            <MdArrowBackIos />
          </ArrowButton>
          {getPaginationNumbers().map((pageNumber) => (
            <PageButton 
              key={pageNumber} 
              onClick={() => changePage(pageNumber)}
              isActive={currentPage === pageNumber}
            >
              {pageNumber}
            </PageButton>
          ))}
          <ArrowButton 
            onClick={() => changePage(Math.min(pages, currentPage + 1))}
            disabled={currentPage === pages}
          >
            <MdArrowForwardIos />
          </ArrowButton>
        </PaginationContainer>
      </Container>
    );
  };
  
  export default Blogs;